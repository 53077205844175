import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { Storage } from '../firebase';
import { collection, getDocs, updateDoc, doc as firestoreDoc , query, where, getDoc, addDoc, Timestamp, deleteDoc, orderBy } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import '../style/OrdersPage.css';
import axios from 'axios';
import ImageModal from '../components/ImageModal'; // Import the ImageModal component
import { jsPDF } from 'jspdf';
import autoTable from "jspdf-autotable";
import { ref, uploadBytesResumable, getDownloadURL, listAll } from 'firebase/storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Wallet from '../components/Wallet';
import { useModal } from '../components/ModalContext';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { numberToWords } from "amount-to-words";
import { ToWords } from "to-words";

const OrdersPage = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null); // State to track selected image for modal
    const [confirmedOrders, setConfirmedOrders] = useState([]); // To track confirmed orders
    const [selectedOrder, setSelectedOrder] = useState(null);   // Order to confirm
    const [confirmBalance, setConfirmBalance] = useState(null); // Balance after deduction
    const [showConfirmModal, setShowConfirmModal] = useState(false); // Modal state
    const { isModalOpen, setIsModalOpen } = useModal();
    const [showCancelModal, setShowCancelModal] = useState(false); // State for cancel confirmation modal
    const [orderToCancel, setOrderToCancel] = useState(null); // Store the order ID to cancel
    const [currentBalance, setCurrentBalance] = useState(null); 
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const shopId = queryParams.get('shop');
    const [currentPage, setCurrentPage] = useState(1);
    const ordersPerPage = 7; // Orders per page
    
  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not logged in");
  }



    useEffect(() => {
        const fetchBalance = async () => {
            const balance = await getCurrentBalance();
            setCurrentBalance(balance);
 // Stop loading after fetching balance
        };

        fetchBalance();
    }, []);

    const fetchDesignDetailsBySKU = async (sku) => {
        if (!sku) {
            console.error("No SKU provided");
            return null;
        }

        try {
            console.log(`Fetching design data for SKU: ${sku}`);
            const designsCollection = collection(db, 'users', user.uid, 'designs');
            const q = query(designsCollection, where('SKUs', 'array-contains', sku));
            const designSnapshot = await getDocs(q);

            

            if (!designSnapshot.empty) {
                const designData = designSnapshot.docs[0].data();
                console.log("Design data found:", designData);

                let pickupLocation = 'Printing Unit'; // Default location if no match
                if (designData.printMethod === 'DTG Epson' || designData.printMethod === 'DTF Epson') {
                    pickupLocation = 'Printing Unit 2';
                } else if (designData.printMethod === 'DTG Brother') {
                    pickupLocation = 'Printing Unit';
                }
                return {
                    ...designData,
                    frontImage: designData.images.front,
                    backImage: designData.images.back,
                    pickupLocation,
                };
            } else {
                console.warn(`No design found for SKU: ${sku}`);
                return null;
            }
        } catch (error) {
            console.error("Error fetching design details by SKU:", error);
            return null;
        }
    };


    const fetchOrders = async () => {
    
        try {
            const ordersCollection = collection(db, 'users', user.uid, 'orders');
            const q = query(ordersCollection, orderBy('createdAt', 'desc'));
            const ordersSnapshot = await getDocs(q);

            console.log("Orders snapshot:", ordersSnapshot);
    
            if (ordersSnapshot.empty) {
                console.warn("No orders found in Firestore.");
                setOrders([]);
                return;
            }
    
            const ordersList = await Promise.all(
                ordersSnapshot.docs.map(async (firestoreDoc) => {
                    const orderData = firestoreDoc.data();
                    const isPaid = orderData.isPaid || false;
    
                    // Skip paid orders
                    if (isPaid) return null;
    
                    console.log(`Fetched order data for order ID: ${firestoreDoc.id}`, orderData);
    
                    if (!orderData.order_items || orderData.order_items.length === 0) {
                        console.warn(`Order ID ${firestoreDoc.id} has no line items.`);
                        return { id: firestoreDoc.id, ...orderData, order_items: [], isPaid };
                    }
    
                    const lineItemsWithDesigns = await Promise.all(
                        orderData.order_items.map(async (item) => {
                            const designData = await fetchDesignDetailsBySKU(item.sku);
                            return { ...item, design: designData };
                        })
                    );
    
                    return { id: firestoreDoc.id, ...orderData, order_items: lineItemsWithDesigns };
                })
            );
    
            // Filter out null entries (i.e., paid orders)
            setOrders(ordersList.filter(order => order !== null));
            console.log("Orders List:", ordersList);
        } catch (error) {
            console.error("Error fetching orders:", error);
        } finally {
            setLoading(false);
        }
    };


    const handleConfirmOrder = async (orderId) => {
        try {
            const order = orders.find(order => order.id === orderId);
            if (!order) {
                toast.error('Order not found');
            }
    
            // Check if the order is already confirmed
            if (confirmedOrders.includes(orderId)) {
                toast.info('This order is already paid.');
            }

            if (order.isPaid) {
                toast.info('This order is already paid.'); // Notify if already paid
            }
            


            // Fetch user's wallet balance
            const walletRef = firestoreDoc(db, 'users', auth.currentUser.uid, 'wallet', 'walletDetails');
            const walletSnap = await getDoc(walletRef);
    
            if (!walletSnap.exists()) {
                toast.error('You need to recharge', { onClose: () => setIsModalOpen(true) });
            }
    
            const walletData = walletSnap.data();
            const currentBalance = walletData.balance;
    
            // Check if the balance is sufficient
            if (currentBalance < totalCost) {
                toast.error('Insufficient balance. Recharge the amount.', { onClose: () => setIsModalOpen(true) });
            }

            
    
            // Set selected order and show the confirmation modal
            setSelectedOrder(order);
            setConfirmBalance(currentBalance - calculateCostWithGST(order)); // Calculate balance after deduction
            setShowConfirmModal(true);
    
        } catch (error) {
            console.error('Error confirming order:', error);
            toast.error('Error confirming order:', error);
        }
    };

    const handleCancelOrderClick = (orderId) => {
        setOrderToCancel(orderId); // Set the order to cancel
        setShowCancelModal(true); // Show the cancel confirmation modal
    };
    

    const confirmCancelOrder = async () => {
        try {
            const orderRef = firestoreDoc(db, 'users', user.uid, 'orders', orderToCancel);
            await deleteDoc(orderRef); // Use deleteDoc to remove the order
            fetchOrders(); // Refresh the order list
            toast.success('Order canceled successfully.');
        } catch (error) {
            console.error("Error canceling order:", error);
            toast.error('Error canceling order.');
        } finally {
            setShowCancelModal(false); // Close the cancel modal
            setOrderToCancel(null); // Clear the order to cancel
        }
    };




    // const calculateShippingChargeAndTotalCost = (order) => {
        // const specialStates = [
        //     "North East", "J & K", "Ladakh", "Andaman & Nicobar", 
        //     "Himachal Pradesh", "Sikkim", "Lakshadweep",
        //     "Arunachal Pradesh", "Assam", "Manipur", "Meghalaya", 
        //     "Mizoram", "Nagaland", "Tripura"
        // ];

        // const billingState = order.billing_state;
        // const paymentMethod = order.payment_method;
        // const sellingPrice = order.sub_total
        // let shippingCharge = 0;

        // // Determine shipping charge based on payment method and billing state
        // if (paymentMethod === "Prepaid") {
        //     shippingCharge = 65;
        // } else if (paymentMethod === "COD") {
        //     shippingCharge = 104;

        //     // Add 2% of selling price to shipping if selling price > 2000
        //     if (sellingPrice > 2000) {
        //         shippingCharge += sellingPrice * 0.02;
        //     }
        // }

        // // If the billing state is a special state, set shipping charge to 100
        // if (billingState && specialStates.includes(billingState)) {
        //     shippingCharge = 100;
        // }

        

    //     // Calculate the total cost
    //     const totalCost = order.order_items.reduce((sum, item) => {
    //         const itemTotal = (item.design?.totalPrice || 0) * item.units;
    //         return sum + itemTotal + shippingCharge;
    //     }, 0);

    //     return totalCost * 1.05; // Apply 5% additional cost
       
    // };



    // const calculateShippingCharge = (order) => {
    //     const specialStates = [
    //         "North East", "J & K", "Ladakh", "Andaman & Nicobar", 
    //         "Himachal Pradesh", "Sikkim", "Lakshadweep",
    //         "Arunachal Pradesh", "Assam", "Manipur", "Meghalaya", 
    //         "Mizoram", "Nagaland", "Tripura"
    //     ];

    //     const billingState = order.billing_state;
    //     const paymentMethod = order.payment_method;
    //     const sellingPrice = order.sub_total
    //     let shippingCharge = 0;

    //     // Determine shipping charge based on payment method and billing state
    //     if (paymentMethod === "Prepaid") {
    //         shippingCharge = 65;
    //     } else if (paymentMethod === "COD") {
    //         shippingCharge = 104;

    //         // Add 2% of selling price to shipping if selling price > 2000
    //         if (sellingPrice > 2000) {
    //             shippingCharge += sellingPrice * 0.02;
    //         }
    //     }

    //     // If the billing state is a special state, set shipping charge to 100
    //     if (billingState && specialStates.includes(billingState)) {
    //         shippingCharge = 100;
    //     }

    //     return shippingCharge;
    // };



    //towords used in invoice

    const toWords = new ToWords({
        localeCode: "en-IN",
        converterOptions: {
          currency: true,
          ignoreDecimal: false,
          ignoreZeroCurrency: false,
          doNotAddOnly: false,
          currencyOptions: {
            name: "Rupee",
            plural: "Rupees",
            symbol: "₹",
            fractionalUnit: {
              name: "Paisa",
              plural: "Paise",
              symbol: "",
            },
          },
        },
      });


//Shipping Price calculation

    // const calculateShippingCharge = (order) => {
    //     // Safeguard for when 'items' might not be passed or is not an array
    //     if (!order || !Array.isArray(order.order_items) || order.order_items.length === 0) {
    //         throw new Error("Order items are missing or invalid.");
    //     }
    
    //     const specialStates = [
    //         "North East", "J & K", "Ladakh", "Andaman & Nicobar", 
    //         "Himachal Pradesh", "Sikkim", "Lakshadweep",
    //         "Arunachal Pradesh", "Assam", "Manipur", "Meghalaya", 
    //         "Mizoram", "Nagaland", "Tripura"
    //     ];
    
    //     const billingState = order.billing_state;
    //     const paymentMethod = order.payment_method;
    //     const sellingPrice = order.sub_total;
    //     const items = order.order_items; // Assuming items is an array with the order items
    //     const itemWeight = 0.25; // Each item weighs 0.25 kg
    
    //     let shippingCharge = 0;
    
    //     // If the billing state is a special state, set specific shipping charges based on payment method
    //     if (billingState && specialStates.includes(billingState)) {
    //         if (paymentMethod === "Prepaid") {
    //             shippingCharge = 85;
    //         } else if (paymentMethod === "COD") {
    //             shippingCharge = 85;
    
    //             // Add 2% of selling price to shipping if selling price > 2000
    //             if (sellingPrice > 2000) {
    //                 shippingCharge += sellingPrice * 0.025;
    //             }
    //             else {
    //                 // If the sub_total is less than or equal to 2000, add 50 to the shipping charge
    //                 shippingCharge += 50;
    //             }
    //         }
    //     } else {
    //         // Default shipping charges based on payment method for non-special states
    //         if (paymentMethod === "Prepaid") {
    //             shippingCharge = 65;
    //         } else if (paymentMethod === "COD") {
    //             shippingCharge = 65;
    
    //             // Add 2.5% of selling price to shipping if selling price > 2000
    //             if (sellingPrice > 2000) {
    //                 shippingCharge += sellingPrice *  0.025;
    //             }
    //             else {
    //                 // If the sub_total is less than or equal to 2000, add 50 to the shipping charge
    //                 shippingCharge += 50;
    //             }
    //         }
    //     }
    
    //     // Calculate the number of "weight groups" (each group is 0.5 kg, which is 2 items)
    //     const totalItems = items.length;
    //     const weightGroups = Math.ceil(totalItems * itemWeight / 0.5); // Number of 0.5 kg groups
    
    //     // Increase shipping charge based on the number of weight groups
    //     // Every group (0.5kg) increases the shipping price
    //     shippingCharge *= weightGroups;
    
    //     return shippingCharge;
    // };
    
    const calculateShippingCharge = (order) => {
        // Safeguard for when 'items' might not be passed or is not an array
        if (!order || !Array.isArray(order.order_items) || order.order_items.length === 0) {
            throw new Error("Order items are missing or invalid.");
        }


        const userId = auth.currentUser?.uid;

        if (userId === "hl6gcaUDIFXQkYUxppfAAHycLtO2") {
        


            const specialStates = [
                "North East", "J & K", "Ladakh", "Andaman & Nicobar", 
                "Himachal Pradesh", "Sikkim", "Lakshadweep",
                "Arunachal Pradesh", "Assam", "Manipur", "Meghalaya", 
                "Mizoram", "Nagaland", "Tripura"
            ];
        
            const billingState = order.billing_state;
            const paymentMethod = order.payment_method;
            const sellingPrice = order.sub_total;
            const items = order.order_items; // Assuming items is an array with the order items
            const itemWeight = 0.25; // Each item weighs 0.25 kg
            
            let shippingCharge = 0;
        
            // Calculate the total number of items by summing up their quantities
            let totalItemQuantity = 0;
            items.forEach(item => {
                totalItemQuantity += item.units; // Assuming each item has a 'quantity' field
            });
        
            // Calculate the total weight of the order
            const totalWeight = totalItemQuantity * itemWeight;
        
            // Calculate the number of "weight groups" (each group is 0.5 kg, which is 2 items)
            const weightGroups = Math.ceil(totalWeight / 0.5); // Number of 0.5 kg groups
        
            // If the billing state is a special state, set specific shipping charges based on payment method
            if (billingState && specialStates.includes(billingState)) {
                if (paymentMethod === "Prepaid") {
                    shippingCharge = 85;
                } else if (paymentMethod === "COD") {
                    shippingCharge = 85;
        
                }
            } else {
                // Default shipping charges based on payment method for non-special states
                if (paymentMethod === "Prepaid") {
                    shippingCharge = 55;
                } else if (paymentMethod === "COD") {
                    shippingCharge = 65;
        
                }
            }
        
            // Increase shipping charge based on the number of weight groups
            shippingCharge *= weightGroups;
    
            if (paymentMethod === "COD") {
                if (sellingPrice > 2000) {
                    shippingCharge += sellingPrice * 0.025; // Add 2.5% of selling price
                } else {
                    // If the selling price is less than or equal to 2000, add 50
                    shippingCharge += 50;
                }
            }
        
            return shippingCharge;
        }
    
        const specialStates = [
            "North East", "J & K", "Ladakh", "Andaman & Nicobar", 
            "Himachal Pradesh", "Sikkim", "Lakshadweep",
            "Arunachal Pradesh", "Assam", "Manipur", "Meghalaya", 
            "Mizoram", "Nagaland", "Tripura"
        ];
    
        const billingState = order.billing_state;
        const paymentMethod = order.payment_method;
        const sellingPrice = order.sub_total;
        const items = order.order_items; // Assuming items is an array with the order items
        const itemWeight = 0.25; // Each item weighs 0.25 kg
        
        let shippingCharge = 0;
    
        // Calculate the total number of items by summing up their quantities
        let totalItemQuantity = 0;
        items.forEach(item => {
            totalItemQuantity += item.units; // Assuming each item has a 'quantity' field
        });
    
        // Calculate the total weight of the order
        const totalWeight = totalItemQuantity * itemWeight;
    
        // Calculate the number of "weight groups" (each group is 0.5 kg, which is 2 items)
        const weightGroups = Math.ceil(totalWeight / 0.5); // Number of 0.5 kg groups
    
        // If the billing state is a special state, set specific shipping charges based on payment method
        if (billingState && specialStates.includes(billingState)) {
            if (paymentMethod === "Prepaid") {
                shippingCharge = 85;
            } else if (paymentMethod === "COD") {
                shippingCharge = 85;
    
            }
        } else {
            // Default shipping charges based on payment method for non-special states
            if (paymentMethod === "Prepaid") {
                shippingCharge = 65;
            } else if (paymentMethod === "COD") {
                shippingCharge = 65;
    
            }
        }
    
        // Increase shipping charge based on the number of weight groups
        shippingCharge *= weightGroups;

        if (paymentMethod === "COD") {
            if (sellingPrice > 2000) {
                shippingCharge += sellingPrice * 0.025; // Add 2.5% of selling price
            } else {
                // If the selling price is less than or equal to 2000, add 50
                shippingCharge += 50;
            }
        }
    
        return shippingCharge;
    };
    
    
const calculateShippingWithGST = (order) => {
    // Calculate the shipping charge first using the existing logic
    let shippingCharge = calculateShippingCharge(order);
    
    // Apply 18% GST on the shipping charge
    const gst = shippingCharge * 0.18; // 18% of shipping charge
    
    // Add the GST to the original shipping charge
    const totalShippingWithGST = shippingCharge + gst;
    
    return totalShippingWithGST;
};

    // Function to calculate total cost
    const calculateTotalCost = (order) => {
        // Ensure that order_items exists and is an array
        if (!Array.isArray(order.order_items)) {
            console.error('order_items is not an array or is undefined');
            return 0; // Return 0 if there's an error
        }
    
        const shippingCharge = calculateShippingCharge(order);
    
        // Use reduce to calculate the total item cost, ensuring the item data is valid
        const totalItemCost = order.order_items.reduce((sum, item) => {
            // Check if item.design exists and has a totalPrice
            if (item.design && typeof item.design.totalPrice === 'number') {
                const itemTotal = (item.design.totalPrice || 0) * item.units;
                return sum + itemTotal;
            } else {
                // If no valid item design or totalPrice, log the issue and return the sum unchanged
                console.warn('Invalid item or missing totalPrice in item:', item);
                return sum;
            }
        }, 0);
    
        // return totalItemCost + shippingCharge;

        return totalItemCost;
        
    };
    

    const CostWithShipping = (order) => {
        // Ensure that order_items exists and is an array
        if (!Array.isArray(order.order_items)) {
            console.error('order_items is not an array or is undefined');
            return 0; // Return 0 if there's an error
        }
    
        const shippingCharge = calculateShippingCharge(order);
    
        // Use reduce to calculate the total item cost, ensuring the item data is valid
        const totalItemCost = order.order_items.reduce((sum, item) => {
            // Check if item.design exists and has a totalPrice
            if (item.design && typeof item.design.totalPrice === 'number') {
                const itemTotal = (item.design.totalPrice || 0) * item.units;
                return sum + itemTotal;
            } else {
                // If no valid item design or totalPrice, log the issue and return the sum unchanged
                console.warn('Invalid item or missing totalPrice in item:', item);
                return sum;
            }
        }, 0);
    
        return totalItemCost + shippingCharge;
        
    };




    const calculateCostWithGST = (order) => {
        const totalCost = calculateTotalCost(order); // Use the previously defined total cost function
        const gstAmount = totalCost * 0.05; // 5% GST
        return totalCost + gstAmount; // Total cost + GST
    };


    

    const getCurrentBalance = async () => {
        try {
            // Fetch the wallet document for the current user
            const walletRef = firestoreDoc(db, 'users', auth.currentUser.uid, 'wallet', 'walletDetails');
            const walletSnap = await getDoc(walletRef);
            
            // Check if the wallet document exists
            if (!walletSnap.exists()) {
                toast.error('You need to recharge', { onClose: () => setIsModalOpen(true) });
                return null;  // Return null if the wallet document doesn't exist
            }
    
            // Extract the current balance from the wallet document
            const walletData = walletSnap.data();
            const currentBalance = walletData.balance;
            
            // Return the current balance
            return currentBalance;
    
        } catch (error) {
            console.error('Error fetching current balance:', error);
            toast.error('Error fetching balance');
            return null;  // Return null if there's an error fetching the data
        }
    };
    



        const handleImageClick = (imageSrc) => {
            setSelectedImage(imageSrc); // Set the clicked image as the selected one
        };

        const handleCloseModal = () => {
            setSelectedImage(null); // Close the modal by clearing the selected image
        };

        useEffect(() => {
            fetchOrders();
        }, );

        if (loading) {
            return <div>Loading orders...</div>;
        }


        const totalPages = Math.ceil(orders.length / ordersPerPage);
        const startIndex = (currentPage - 1) * ordersPerPage;
        const paginatedOrders = orders.slice(startIndex, startIndex + ordersPerPage);
        
        const confirmOrder = async () => {
            try {
                // Initialize references
                const walletRef = firestoreDoc(db, 'users', auth.currentUser.uid, 'wallet', 'walletDetails');
        
                // Fetch the user's wallet details
                const walletSnap = await getDoc(walletRef);
                if (!walletSnap.exists()) {
                    toast.error('You need to recharge', { onClose: () => setIsModalOpen(true) });
                    return;
                }
        
                const walletData = walletSnap.data();
                const currentBalance = walletData.balance;
        
                // Check if the balance is sufficient
                if (currentBalance < (calculateCostWithGST(selectedOrder) + calculateShippingWithGST(selectedOrder))) {
                    toast.error('Insufficient balance. Recharge the amount.', { onClose: () => setIsModalOpen(true) });
                    return;
                }
        
                // Fetch shop data (brand details) from Firestore
                const userRef = firestoreDoc(db, 'users', user.uid); // Assuming `shopId` is available
                const userSnap = await getDoc(userRef);
                if (!userSnap.exists()) {
                    toast.error('Shop information not found.');
                    return;
                }
                const userData = userSnap.data();

                // const brandingRef = collection(db, 'users', auth.currentUser.uid, 'branding');
                // const brandingSnap = await getDocs(brandingRef);
                // const brandingItems = brandingSnap.docs.map(doc => ({ brandingId: doc.id, ...doc.data() }));
                
                // // Loop through selected order items and reduce the quantity
                // for (let item of selectedOrder.order_items) {
                //     const orderedQuantity = item.units;
                
                //     for (let brandingItem of brandingItems) {
                //         const currentQuantity = brandingItem.quantity;
                
                //         // Check if there is enough stock
                //         if (currentQuantity >= orderedQuantity) {
                //             // Reduce the quantity in Firestore
                //             const itemRef = firestoreDoc(db, 'users', auth.currentUser.uid, 'branding', brandingItem.brandingId);
                //             await updateDoc(itemRef, { quantity: currentQuantity - orderedQuantity });
                
                //             toast.success(`Item quantity updated!`);
                //             break;  // Exit loop after successfully updating one item
                //         } else {
                //             toast.error(`Insufficient stock for item with branding ID ${brandingItem.brandingId}`);
                //             return; // Stop processing if stock is insufficient
                //         }
                //     }
                // }
                const brandingRef = collection(db, 'users', auth.currentUser.uid, 'branding');
                const brandingSnap = await getDocs(brandingRef);
                const brandingItems = brandingSnap.docs.map(doc => ({ brandingId: doc.id, ...doc.data() }));
                
                // Loop through selected order items and calculate total ordered quantity
                let totalOrderedQuantity = selectedOrder.order_items.reduce((sum, item) => sum + item.units, 0);
                
                // Loop through branding items to reduce stock
                for (let item of selectedOrder.order_items) {
                    let remainingQuantity = totalOrderedQuantity; // Track remaining units to reduce
                    
                    for (let brandingItem of brandingItems) {
                        const currentQuantity = brandingItem.quantity;
                
                        // Check if the current branding item has enough stock
                        if (currentQuantity >= remainingQuantity) {
                            // Reduce the stock for the full ordered quantity
                            const itemRef = firestoreDoc(db, 'users', auth.currentUser.uid, 'branding', brandingItem.brandingId);
                            await updateDoc(itemRef, { quantity: currentQuantity - remainingQuantity });
                            break;  // Stop once the ordered quantity has been fulfilled
                        } else if (currentQuantity > 0) {
                            // If not enough stock, reduce what we can
                            const itemRef = firestoreDoc(db, 'users', auth.currentUser.uid, 'branding', brandingItem.brandingId);
                            await updateDoc(itemRef, { quantity: 0 });
                            remainingQuantity -= currentQuantity;  // Deduct the available stock
                        }
                    }
                }
                





                const ordersRef = firestoreDoc(db, 'users', user.uid, 'orders', selectedOrder.id); // Assuming `shopId` is available
                const ordersSnap = await getDoc(ordersRef);
                if (!ordersSnap.exists()) {
                    toast.error('Shop information not found.');
                    return;
                }
                const ordersData = ordersSnap.data();


                const CustomerInfo = {
                    Name: ordersData.billing_customer_name || 'Default Brand Name',
                    address: ordersData.billing_address || 'N/A',
                    city: ordersData.billing_city || 'N/A',         
                    state: ordersData.billing_state || 'N/A',     
                    pincode:ordersData.billing_pincode || 'N/A',
                    country: ordersData.billing_country || 'N/A',
                };
        

                
                const brandInfo = {
                    brandName: userData.brandName || 'Default Brand Name',
                    address: userData.address || 'N/A',
                    state: userData.state || 'N/A',         
                    pinCode: userData.pincode || 'N/A',     
                    gstNumber: userData.gstNumber || 'N/A',
                    contact: userData.phoneNumber || 'N/A',
                };
        
                const mainInfo = {
                    name: 'UNITEE STUDIOS PRIVATE LIMITED',
                };
        
                const invoiceDate = new Date().toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: '2-digit',
                });
        

                const storageRef = ref(Storage, 'invoices/');

                // Get the list of items (invoices) in the 'invoices/' folder
                const listResult = await listAll(storageRef);
                let nextInvoiceNumber = 1;  // Default to 1 if no invoices exist
                
                if (listResult.items.length > 0) {
                    // Extract the numeric part of each file name (e.g., 'invoice_001.pdf')
                    const invoiceNumbers = listResult.items
                        .map(item => item.name.match(/invoice_(\d+)\.pdf$/))  // Adjusting regex to capture invoice number
                        .filter(match => match)  // Filter out invalid names
                        .map(match => parseInt(match[1], 10));  // Parse the numeric part of the invoice number
                
                    // Find the highest invoice number and increment it
                    if (invoiceNumbers.length > 0) {
                        nextInvoiceNumber = Math.max(...invoiceNumbers) + 1;  // Increment the highest invoice number by 1
                    }
                }
                
                // Format the invoice number (e.g., 'UC/2024-25/001')
                const invoiceNumber = `UC/2025-26/${String(nextInvoiceNumber).padStart(3, '0')}`;
                
                // Rest of the code for creating the invoice and uploading it...
                

                const doc = new jsPDF();

                // Header Section
                doc.setFontSize(20);
        
                doc.text('TAX INVOICE', 100, 15, null, null, 'center');
                
                // Company Information
                doc.setFontSize(10);
                doc.setFont("Helvetica", "normal");
                doc.text("UNITEE STUDIOS PRIVATE LIMITED", 10, 20);
                doc.text("Maharashtra, India", 10, 25);
                doc.text("GSTIN 27AADCU3575G1Z5", 10, 30);
                doc.text("rajprit@uniteeculture.com", 10, 35);
                doc.text("1ST FLOOR, PLOT 304/E/, 3, WAKHARIA BUILDING Vithalbhai Patel Road", 10, 40);
                doc.text("MUMBAI", 10, 45);
                
                // Invoice Details
                doc.text("Invoice Details", 150, 25, null, null, 'center');
                doc.setFontSize(10);
                doc.text(`Invoice Date: ${invoiceDate}`, 140, 30);
                doc.text(`Invoice Number: ${invoiceNumber}`, 140, 35);
                // doc.text(`Terms: Due On Receipt`, 140, 40);
                // doc.text("Due Date: 11/11/2024", 140, 45);
                // doc.text("Place of Supply: Maharashtra (27)", 140, 50);
                
                // Billing and Shipping Information
                doc.text("Bill To", 10, 60);
                doc.text(`Name: ${brandInfo.brandName}`, 10, 65);
                doc.text(`Address: ${brandInfo.address}`, 10, 70);
                doc.text(`State: ${brandInfo.state}`, 10, 75);
                doc.text(`Pin Code: ${brandInfo.pinCode}`, 10, 80);
                doc.text(`GST Number: ${brandInfo.gstNumber}`, 10, 85);
                
                doc.text("Ship To", 140, 60);
                doc.text(`Name: ${CustomerInfo.Name}`, 140, 65);
                doc.text(`Address: ${CustomerInfo.address}`, 140, 70);
                doc.text(`City: ${CustomerInfo.city}`, 140, 75);
                doc.text(`State: ${CustomerInfo.state}`, 140, 80);           
                doc.text(`Pin Code: ${CustomerInfo.pincode}`, 140, 85);


                // doc.text("Ship To", 140, 60);
                // doc.text(`Name: ${billingInfo.name}`, 140, 65);
                // doc.text(`Address: ${billingInfo.address}`, 140, 70);
                // doc.text(`City: ${billingInfo.city}`, 140, 75);
                // doc.text(`State: ${billingInfo.state}`, 140, 80);
                // doc.text(`Pin Code: ${billingInfo.pincode}`, 140, 85);
                // doc.text(`Country: ${billingInfo.country}`, 140, 90);
                
    const itemsTableY = 90;
    autoTable(doc, {
        startY: itemsTableY,
        head: [
            ['#', 'Item & Description', 'HSN/SAC', 'Qty', 'Rate', 
             brandInfo.state === "Maharashtra" ? 'CGST %' : 'IGST %', 
             brandInfo.state === "Maharashtra" ? 'CGST Amt' : 'IGST Amt', 
             brandInfo.state === "Maharashtra" ? 'SGST %' : '', 
             brandInfo.state === "Maharashtra" ? 'SGST Amt' : '', 
             brandInfo.state !== "Maharashtra" ? 'IGST %' : '', 
             brandInfo.state !== "Maharashtra" ? 'IGST Amt' : '', 
             'Amount'
            ]
        ],
        body: selectedOrder.order_items.map((item, index) => {
            // Calculate the individual item price (including shipping)
            // const itemPrice = (item.design?.totalPrice || 0) * item.units + shippingCharge;  // Item price + shipping
            const itemPrice = (item.design?.totalPrice || 0);
            let cgstAmt = 0;
            let sgstAmt = 0;
            let igstAmt = 0;
            let taxAmount = 0;
    
            if (brandInfo.state === "Maharashtra") {
                // **CGST and SGST for Maharashtra**
                cgstAmt = (itemPrice * 0.025).toFixed(2);  // 2.5% CGST
                sgstAmt = (itemPrice * 0.025).toFixed(2);  // 2.5% SGST
                taxAmount = (parseFloat(cgstAmt) + parseFloat(sgstAmt)).toFixed(2);  // Total CGST + SGST
            } else {
                // **IGST for other states**
                igstAmt = (itemPrice * 0.05).toFixed(2);  // 5% IGST
                taxAmount = igstAmt;  // Only IGST
            }
    
            // Calculate total amount per item (including tax)
            const amount = (itemPrice + parseFloat(cgstAmt || igstAmt) + parseFloat(sgstAmt || 0)).toFixed(2);
    
            return [
                index + 1,
                item.design?.designName || 'Unknown',
                '610910',  // Example HSN code
                item.units,
                itemPrice.toFixed(2),
                // **Display CGST and SGST for Maharashtra, IGST for others**
                brandInfo.state === "Maharashtra" ? '2.5%' : '', 
                brandInfo.state === "Maharashtra" ? cgstAmt : '',
                brandInfo.state === "Maharashtra" ? '2.5%' : '', 
                brandInfo.state === "Maharashtra" ? sgstAmt : '',
                brandInfo.state !== "Maharashtra" ? '5%' : '', 
                brandInfo.state !== "Maharashtra" ? igstAmt : '',
                amount,
            ];
        }),
        theme: 'grid',
        styles: { fontSize: 10 },
    });

                
                // Tax and Summary Table
                let totalTaxableValue = 0;
                let totalCGST = 0;
                let totalSGST = 0;
                let totalIGST = 0;
                
                selectedOrder.order_items.forEach(item => {
                    // Calculate the item price (including shipping)
                    const itemPrice = (item.design?.totalPrice || 0);  // Item price + shipping
                    const taxableValue = itemPrice * item.units;
                    totalTaxableValue += taxableValue;
                
                    // **Apply CGST, SGST for Maharashtra and IGST for others**
                    if (brandInfo.state === "Maharashtra") {
                        totalCGST += taxableValue * 0.025;  // **CGST**
                        totalSGST += taxableValue * 0.025;  // **SGST**
                    } else {
                        totalIGST += taxableValue * 0.05;  // **IGST**
                    }
                });
                
                const taxSummaryY = doc.autoTable.previous.finalY + 10;
                autoTable(doc, {
                    startY: taxSummaryY,
                    head: [['HSN/SAC', 'Taxable Value', 
                        brandInfo.state === "Maharashtra" ? 'CGST %' : 'IGST %', 
                        brandInfo.state === "Maharashtra" ? 'CGST Amt' : 'IGST Amt', 
                        brandInfo.state === "Maharashtra" ? 'SGST %' : '', 
                        brandInfo.state === "Maharashtra" ? 'SGST Amt' : '', 
                        brandInfo.state !== "Maharashtra" ? 'IGST %' : '', 
                        brandInfo.state !== "Maharashtra" ? 'IGST Amt' : '',
                        'Total Tax Amount']],
                    body: [
                        ['610910', totalTaxableValue.toFixed(2),
                            // **Display CGST/SGST/IGST based on state**
                            brandInfo.state === "Maharashtra" ? '2.5%' : '', 
                            brandInfo.state === "Maharashtra" ? totalCGST.toFixed(2) : '',
                            brandInfo.state === "Maharashtra" ? '2.5%' : '', 
                            brandInfo.state === "Maharashtra" ? totalSGST.toFixed(2) : '',
                            brandInfo.state !== "Maharashtra" ? '5%' : '', 
                            brandInfo.state !== "Maharashtra" ? totalIGST.toFixed(2) : '',
                            (totalCGST + totalSGST + totalIGST).toFixed(2)]
                    ],
                    theme: 'grid',
                    styles: { fontSize: 10 },
                });
            
                const shippingBase = (calculateShippingCharge(selectedOrder));  // Calculate base shipping charge (before 18% tax)
                const shippingTax = (calculateShippingCharge(selectedOrder) * 0.18);  // Calculate the tax part (18% of base)
                
                // Now `shippingBase`, `shippingTax`, and `shippingCharge` are dynamically calculated
                
                const shippingTableY = doc.autoTable.previous.finalY + 10;
                autoTable(doc, {
                    startY: shippingTableY,
                    head: [['SAC', 'Shipping Charge', 'Rate (18%)','Total Shipping Charge' ]],
                    body: [
                        ['9965', shippingBase, shippingTax, calculateShippingWithGST(selectedOrder).toFixed(2) ],    // Total shipping charge including tax
                    ],
                    theme: 'grid',
                    styles: { fontSize: 10 },
                });
                // autoTable(doc, {
                //     startY: shippingTableY,
                //     head: [['Description', 'Amount']],
                //     body: [
                //         ['Shipping Charge (Before Tax)', shippingBase],  // Base shipping charge
                //         ['Shipping Tax (18%)', shippingTax],  // Tax on shipping charge
                //         ['Total Shipping Charge', calculateShippingCharge(selectedOrder).toFixed(2)],  // Total shipping charge including tax
                //     ],
                //     theme: 'grid',
                //     styles: { fontSize: 10 },
                // });


            // Final Amount Summary
            const totalAmount = totalTaxableValue + totalCGST + totalSGST + totalIGST + calculateShippingWithGST(selectedOrder);
            const finalAmountY = doc.autoTable.previous.finalY + 10;
            doc.text(`Amount Chargeable (in words): ${toWords.convert(totalAmount)}`, 10, finalAmountY);
            doc.text(`Total: ${totalAmount.toFixed(2)}`, 150, finalAmountY + 5);
            doc.text(`Balance Due: ${totalAmount.toFixed(2)}`, 150, finalAmountY + 10);
            
            // Footer Notes
            doc.text("Thanks for your business.", 10, finalAmountY + 25);
            doc.text("Authorized Signature", 150, finalAmountY + 25);
            

            const invoiceFileName = `invoice_${nextInvoiceNumber}.pdf`;  // Updated to use nextInvoiceNumber
            const pdfBlob = doc.output('blob');  // Generate the PDF blob as usual
    
            // Upload the invoice to Firebase Storage
            const invoiceRef = ref(Storage, `invoices/${invoiceFileName}`);
            const uploadTask = uploadBytesResumable(invoiceRef, pdfBlob);
    
            // Observe the upload task
            uploadTask.on(
                'state_changed',
                snapshot => {
                    // You can handle upload progress here (e.g., using a progress bar)
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log(`Upload is ${progress}% done`);
                },
                error => {
                    console.error('Error uploading invoice:', error);
                    toast.error('Error uploading invoice');
                },
                async () => {
                    try {
                        // After upload is complete, get the download URL
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        console.log('Invoice uploaded, download URL:', downloadURL);
    
                        // Fetch order data for Shiprocket
                        const orderRef = firestoreDoc(db, 'users', user.uid, 'orders', selectedOrder.id);
                        const orderSnapshot = await getDoc(orderRef);
                        const orderData = { id: selectedOrder.id, ...orderSnapshot.data() };

                        const orderWithPickupLocation = await Promise.all(
                            orderData.order_items.map(async (item) => {
                                const designData = await fetchDesignDetailsBySKU(item.sku);
                                return { ...item, design: designData };
                            })
                        );
                
                        // Create the updated order object with pickup location and other necessary details
                        const updatedOrder = {
                            ...orderData,
                            user_uid: auth.currentUser.uid,
                            order_items: orderWithPickupLocation,
                            pickup_location: orderWithPickupLocation[0]?.design?.pickupLocation || 'Default Location', // Set the pickup location for the order
                        };
    
                        // Create order on Shiprocket
                        console.log("Creating order on Shiprocket...");
                        const response = await axios.post('https://ufb-1.onrender.com/create-shiprocket-order', updatedOrder, {
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        });
                        console.log('Order created on Shiprocket successfully:', response.data);
    
                        // Deduct the total cost from the current balance
                        const updatedBalance = currentBalance - (calculateCostWithGST(selectedOrder) + calculateShippingWithGST(selectedOrder));
    
                        // Update wallet balance
                        console.log("Updating wallet balance...");
                        await updateDoc(walletRef, { balance: updatedBalance });
                        console.log("Wallet balance updated successfully.");
    
                        // Record the transaction
                        const transactionsRef = collection(walletRef, 'transactions');
                        const transactionData = {
                            type: selectedOrder.id,
                            amount: (calculateCostWithGST(selectedOrder) + calculateShippingWithGST(selectedOrder)),
                            balanceBefore: currentBalance,
                            balanceAfter: updatedBalance,
                            // productName: selectedOrder.order_items.map(item => item.design?.designName).join(', '),
                            timestamp: Timestamp.now(),
                        };
                        console.log("Recording transaction data:", transactionData);
                        await addDoc(transactionsRef, transactionData);
                        console.log("Transaction recorded successfully.");
    
                        // Mark the order as confirmed and paid
                        await updateDoc(orderRef, {
                            UniteeTotalPrice: (calculateCostWithGST(selectedOrder) + calculateShippingWithGST(selectedOrder)),
                            status: 'confirmed',
                            isPaid: true, // Update the order to indicate it's paid
                            invoiceUrl: downloadURL // Store the invoice URL
                        });
                        console.log("Order status updated successfully.");
    
                        // Mark the order as confirmed
                        setConfirmedOrders([...confirmedOrders, selectedOrder.id]);
    
                        toast.success('Order confirmed and invoice generated!');
                        setShowConfirmModal(false); // Close modal
                        fetchOrders(); // Refresh the orders
                    } catch (err) {
                        console.error('Error handling completed upload:', err);
                        toast.error('Error handling completed upload.');
                    }
                }
            );
        } catch (error) {
            console.error('Error in confirmOrder function:', error);
    
            // Handle error response specifically for Shiprocket
            if (error.response) {
                console.error('Error response from Shiprocket:', error.response.data);
                toast.error(`Error: ${error.response.data.error || 'An unexpected error occurred.'}`);
            } else {
                console.error('Error confirming order:', error);
                toast.error('An error occurred while confirming the order.');
            }
        }
    };


    
   

       let totalCost = 0;
    // let shippingCharge = 65;

    if (showConfirmModal && selectedOrder) {
        totalCost = calculateCostWithGST(selectedOrder);
    }

    return (
        <div className="orders-page">
            <Header />
            <div className="orders-container">
                <Sidebar />
                <div className="main-content">
                    <div className='ordersp'>Confirm your orders, and we'll start printing right away!</div>
                    <h1>Orders</h1>

                    {showConfirmModal && selectedOrder && (
                      
                      

                        <div className="modals">
                            <div className="modal-contents">
                                <h3>Confirm Order</h3>
                                <p>
                                    Are you sure you want to confirm the order for{' '}
                                    {selectedOrder.order_items.map(item => item.design?.designName).join(', ')}?
                                </p>
                                <p>
                                    Current Balance: ₹{currentBalance.toFixed(2)}
                                </p>
                                
                                <p>Total Cost: ₹ {(calculateCostWithGST(selectedOrder) + calculateShippingWithGST(selectedOrder)).toFixed(2)}</p>
                                <p>Balance After Deduction: ₹{(currentBalance - (calculateCostWithGST(selectedOrder) + calculateShippingWithGST(selectedOrder))).toFixed(2)}</p>
                                <button onClick={confirmOrder}>Yes, Confirm</button>
                                <button onClick={() => setShowConfirmModal(false)}>Cancel</button>
                            </div>
                        </div>
                    )}

                    {showCancelModal && (
                        <div className="modals">
                            <div className="modal-contents">
                                <h3>Cancel Order</h3>
                                <p>Are you sure you want to cancel this order?</p>
                                <button onClick={confirmCancelOrder}>Yes, Cancel</button>
                                <button onClick={() => setShowCancelModal(false)}>No</button>
                            </div>
                        </div>
                    )}

                    <ToastContainer />
                    {isModalOpen && <Wallet />}
                    <table className="orders-table">
                        <thead>
                            <tr>
                                <th>Order ID</th>
                                <th className='hideee'>Unitee Order ID</th>
                                <th>Design Name</th>
                                {/* <th>SKU</th> */}
                                <th className='hideee'>Quantity</th>
                                <th className='hideee'>GSM</th>
                                <th className='hideee'>Selling Price</th>
                                <th className='hideee'>Items Ordered</th>
                                <th>Front Image</th>
                                <th>Back Image</th>
                                <th className='hideee'>Base Cost</th>
                                <th className='hideee'>Print Cost</th>
                                <th className='hideee'>Product Tax</th>
                                <th className='hideee'>Shipping Cost</th>
                                <th className='hideee'>Shipping Tax</th>
                                <th>Total Cost</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {paginatedOrders.map((order) => (
                                <tr key={order.id}>
                                    <td>{order.Storeorder_id || 'NA'}</td>
                                    <td className='hideee'>{order.id}</td> 
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.design?.designName || 'Unknown'}</p>
                                        ))}
                                    </td>
                                    {/* <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.sku}</p>
                                        ))}
                                    </td> */}
                                    <td className='hideee'>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.units}</p>
                                        ))}
                                    </td>
                                    <td className='hideee'>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.design?.productGSM || 'N/A'}</p>
                                        ))}
                                    </td>
                                    <td className='hideee'> 
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.selling_price}</p>
                                        ))}
                                    </td>
                                    <td className='hideee'>{order.order_items.reduce((sum, item) => sum + item.units, 0)}</td>
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>
                                                {item.design?.frontImage ? (
                                                    <img src={item.design.frontImage} alt="Front Design" style={{ width: '50px', height: 'auto' }} onClick={() => handleImageClick(item.design.frontImage)} />
                                                ) : (
                                                    'N/A'
                                                )}
                                            </p>
                                        ))}
                                    </td>
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>
                                                {item.design?.backImage ? (
                                                    <img src={item.design.backImage} alt="Back Design" style={{ width: '50px', height: 'auto' }} onClick={() => handleImageClick(item.design.backImage)} />
                                                ) : (
                                                    'N/A'
                                                )}
                                            </p>
                                        ))}
                                    </td>
                                    <td className='hideee'>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.design?.baseCost || 'N/A'}</p>
                                        ))}
                                    </td>
                                    <td className='hideee'>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.design?.PrintCost || 'N/A'}</p>
                                        ))}
                                    </td>

                                    <td className='hideee'>
                                      {calculateTotalCost(order) * 0.05}
                                    </td>
                                    <td className='hideee'>
                                    {calculateShippingCharge(order)}
                                        {/* {order.order_items.map((item) => (
                                            <p key={item.sku}>{calculateShippingCharge(order)}</p> // Static shipping cost
                                        ))} */}
                                    </td>

                                    <td className='hideee'>
                                      {calculateShippingCharge(order) * 0.18}
                                    </td>
                                    <td>
                                        {/* {order.order_items.reduce((sum, item) => sum + (item.design?.totalPrice || 0) * item.units + shippingCharge, 0)}  */}
                                        {(calculateCostWithGST(order) + calculateShippingWithGST(order)).toFixed(2)}
                                    </td>
                                    <td>
                                        <button className="confirm-button" onClick={() => handleConfirmOrder(order.id)} disabled={order.isPaid}>
                                            {order.isPaid ? 'Paid' : 'Confirm'}
                                        </button>
                                        <button className="cancel-button" onClick={() => handleCancelOrderClick(order.id)}>Cancel</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination-controls">
                        <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                            Previous
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
                            Next
                        </button>
                    </div>
                    <ImageModal imageSrc={selectedImage} onClose={handleCloseModal} />
                </div>
            </div>
        </div>
    );
};
export default OrdersPage;



// import React, { useEffect, useState } from 'react';
// import { db, auth } from '../firebase';
// import { Storage } from '../firebase';
// import { collection, getDocs, updateDoc, doc as firestoreDoc , query, where, getDoc, addDoc, Timestamp, deleteDoc, orderBy } from 'firebase/firestore';
// import { useLocation } from 'react-router-dom';
// import '../style/OrdersPage.css';
// import axios from 'axios';
// import ImageModal from '../components/ImageModal'; // Import the ImageModal component
// import { jsPDF } from 'jspdf';
// import autoTable from "jspdf-autotable";
// import { ref, uploadBytesResumable, getDownloadURL, listAll } from 'firebase/storage';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Wallet from '../components/Wallet';
// import { useModal } from '../components/ModalContext';
// import Header from '../components/Header';
// import Sidebar from '../components/Sidebar';
// import { numberToWords } from "amount-to-words";

// const OrdersPage = () => {
//     const [orders, setOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [selectedImage, setSelectedImage] = useState(null); // State to track selected image for modal
//     const [confirmedOrders, setConfirmedOrders] = useState([]); // To track confirmed orders
//     const [selectedOrder, setSelectedOrder] = useState(null);   // Order to confirm
//     const [confirmBalance, setConfirmBalance] = useState(null); // Balance after deduction
//     const [showConfirmModal, setShowConfirmModal] = useState(false); // Modal state
//     const { isModalOpen, setIsModalOpen } = useModal();
//     const [showCancelModal, setShowCancelModal] = useState(false); // State for cancel confirmation modal
//     const [orderToCancel, setOrderToCancel] = useState(null); // Store the order ID to cancel
//     const [currentBalance, setCurrentBalance] = useState(null); 
//     const location = useLocation();
//     const queryParams = new URLSearchParams(location.search);
//     const shopId = queryParams.get('shop');
//     const [currentPage, setCurrentPage] = useState(1);
//     const ordersPerPage = 7; // Orders per page
    
//   const user = auth.currentUser;
//   if (!user) {
//     throw new Error("User not logged in");
//   }



//     useEffect(() => {
//         const fetchBalance = async () => {
//             const balance = await getCurrentBalance();
//             setCurrentBalance(balance);
//  // Stop loading after fetching balance
//         };

//         fetchBalance();
//     }, []);

//     const fetchDesignDetailsBySKU = async (sku) => {
//         if (!sku) {
//             console.error("No SKU provided");
//             return null;
//         }

//         try {
//             console.log(`Fetching design data for SKU: ${sku}`);
//             const designsCollection = collection(db, 'users', user.uid, 'designs');
//             const q = query(designsCollection, where('SKUs', 'array-contains', sku));
//             const designSnapshot = await getDocs(q);

            

//             if (!designSnapshot.empty) {
//                 const designData = designSnapshot.docs[0].data();
//                 console.log("Design data found:", designData);

//                 let pickupLocation = 'Printing Unit'; // Default location if no match
//                 if (designData.printMethod === 'DTG Epson' || designData.printMethod === 'DTF Epson') {
//                     pickupLocation = 'Printing Unit 2';
//                 } else if (designData.printMethod === 'DTG Brother') {
//                     pickupLocation = 'Printing Unit';
//                 }
//                 return {
//                     ...designData,
//                     frontImage: designData.images.front,
//                     backImage: designData.images.back,
//                     pickupLocation,
//                 };
//             } else {
//                 console.warn(`No design found for SKU: ${sku}`);
//                 return null;
//             }
//         } catch (error) {
//             console.error("Error fetching design details by SKU:", error);
//             return null;
//         }
//     };


//     const fetchOrders = async () => {
//         // if (!shopId) {
//         //     console.error("Shop ID is missing");
//         //     setLoading(false);
//         //     return;
//         // }
    
//         try {
//             const ordersCollection = collection(db, 'users', user.uid, 'orders');
//             const q = query(ordersCollection, orderBy('createdAt', 'desc'));
//             const ordersSnapshot = await getDocs(q);

//             console.log("Orders snapshot:", ordersSnapshot);
    
//             if (ordersSnapshot.empty) {
//                 console.warn("No orders found in Firestore.");
//                 setOrders([]);
//                 return;
//             }
    
//             const ordersList = await Promise.all(
//                 ordersSnapshot.docs.map(async (firestoreDoc) => {
//                     const orderData = firestoreDoc.data();
//                     const isPaid = orderData.isPaid || false;
    
//                     // Skip paid orders
//                     if (isPaid) return null;
    
//                     console.log(`Fetched order data for order ID: ${firestoreDoc.id}`, orderData);
    
//                     if (!orderData.order_items || orderData.order_items.length === 0) {
//                         console.warn(`Order ID ${firestoreDoc.id} has no line items.`);
//                         return { id: firestoreDoc.id, ...orderData, order_items: [], isPaid };
//                     }
    
//                     const lineItemsWithDesigns = await Promise.all(
//                         orderData.order_items.map(async (item) => {
//                             const designData = await fetchDesignDetailsBySKU(item.sku);
//                             return { ...item, design: designData };
//                         })
//                     );
    
//                     return { id: firestoreDoc.id, ...orderData, order_items: lineItemsWithDesigns };
//                 })
//             );
    
//             // Filter out null entries (i.e., paid orders)
//             setOrders(ordersList.filter(order => order !== null));
//             console.log("Orders List:", ordersList);
//         } catch (error) {
//             console.error("Error fetching orders:", error);
//         } finally {
//             setLoading(false);
//         }
//     };


//     const handleConfirmOrder = async (orderId) => {
//         try {
//             const order = orders.find(order => order.id === orderId);
//             if (!order) {
//                 toast.error('Order not found');
//             }
    
//             // Check if the order is already confirmed
//             if (confirmedOrders.includes(orderId)) {
//                 toast.info('This order is already paid.');
//             }

//             if (order.isPaid) {
//                 toast.info('This order is already paid.'); // Notify if already paid
//             }
            

    
//             // const totalCost = order.order_items.reduce((sum, item) => {
//             //     return sum + (item.design?.totalPrice || 0) * item.units;
//             // }, 0);


//             // Fetch user's wallet balance
//             const walletRef = firestoreDoc(db, 'users', auth.currentUser.uid, 'wallet', 'walletDetails');
//             const walletSnap = await getDoc(walletRef);
    
//             if (!walletSnap.exists()) {
//                 toast.error('You need to recharge', { onClose: () => setIsModalOpen(true) });
//             }
    
//             const walletData = walletSnap.data();
//             const currentBalance = walletData.balance;
    
//             // Check if the balance is sufficient
//             if (currentBalance < totalCost) {
//                 toast.error('Insufficient balance. Recharge the amount.', { onClose: () => setIsModalOpen(true) });
//             }

            
    
//             // Set selected order and show the confirmation modal
//             setSelectedOrder(order);
//             setConfirmBalance(currentBalance - calculateCostWithGST(order)); // Calculate balance after deduction
//             setShowConfirmModal(true);
    
//         } catch (error) {
//             console.error('Error confirming order:', error);
//             toast.error('Error confirming order:', error);
//         }
//     };

//     const handleCancelOrderClick = (orderId) => {
//         setOrderToCancel(orderId); // Set the order to cancel
//         setShowCancelModal(true); // Show the cancel confirmation modal
//     };
    

//     const confirmCancelOrder = async () => {
//         try {
//             const orderRef = firestoreDoc(db, 'users', user.uid, 'orders', orderToCancel);
//             await deleteDoc(orderRef); // Use deleteDoc to remove the order
//             fetchOrders(); // Refresh the order list
//             toast.success('Order canceled successfully.');
//         } catch (error) {
//             console.error("Error canceling order:", error);
//             toast.error('Error canceling order.');
//         } finally {
//             setShowCancelModal(false); // Close the cancel modal
//             setOrderToCancel(null); // Clear the order to cancel
//         }
//     };

//     const handleCancelOrder = async (orderId) => {
//         try {
//             const orderRef = firestoreDoc(db, 'users', user.uid, 'orders', orderId);
//             await deleteDoc(orderRef); // Use deleteDoc to remove the order
//             fetchOrders(); // Refresh the order list
//         } catch (error) {
//             console.error("Error canceling order:", error);
//         }
//     };



//     // const calculateShippingChargeAndTotalCost = (order) => {
//         // const specialStates = [
//         //     "North East", "J & K", "Ladakh", "Andaman & Nicobar", 
//         //     "Himachal Pradesh", "Sikkim", "Lakshadweep",
//         //     "Arunachal Pradesh", "Assam", "Manipur", "Meghalaya", 
//         //     "Mizoram", "Nagaland", "Tripura"
//         // ];

//         // const billingState = order.billing_state;
//         // const paymentMethod = order.payment_method;
//         // const sellingPrice = order.sub_total
//         // let shippingCharge = 0;

//         // // Determine shipping charge based on payment method and billing state
//         // if (paymentMethod === "Prepaid") {
//         //     shippingCharge = 65;
//         // } else if (paymentMethod === "COD") {
//         //     shippingCharge = 104;

//         //     // Add 2% of selling price to shipping if selling price > 2000
//         //     if (sellingPrice > 2000) {
//         //         shippingCharge += sellingPrice * 0.02;
//         //     }
//         // }

//         // // If the billing state is a special state, set shipping charge to 100
//         // if (billingState && specialStates.includes(billingState)) {
//         //     shippingCharge = 100;
//         // }

        

//     //     // Calculate the total cost
//     //     const totalCost = order.order_items.reduce((sum, item) => {
//     //         const itemTotal = (item.design?.totalPrice || 0) * item.units;
//     //         return sum + itemTotal + shippingCharge;
//     //     }, 0);

//     //     return totalCost * 1.05; // Apply 5% additional cost
       
//     // };



//     // const calculateShippingCharge = (order) => {
//     //     const specialStates = [
//     //         "North East", "J & K", "Ladakh", "Andaman & Nicobar", 
//     //         "Himachal Pradesh", "Sikkim", "Lakshadweep",
//     //         "Arunachal Pradesh", "Assam", "Manipur", "Meghalaya", 
//     //         "Mizoram", "Nagaland", "Tripura"
//     //     ];

//     //     const billingState = order.billing_state;
//     //     const paymentMethod = order.payment_method;
//     //     const sellingPrice = order.sub_total
//     //     let shippingCharge = 0;

//     //     // Determine shipping charge based on payment method and billing state
//     //     if (paymentMethod === "Prepaid") {
//     //         shippingCharge = 65;
//     //     } else if (paymentMethod === "COD") {
//     //         shippingCharge = 104;

//     //         // Add 2% of selling price to shipping if selling price > 2000
//     //         if (sellingPrice > 2000) {
//     //             shippingCharge += sellingPrice * 0.02;
//     //         }
//     //     }

//     //     // If the billing state is a special state, set shipping charge to 100
//     //     if (billingState && specialStates.includes(billingState)) {
//     //         shippingCharge = 100;
//     //     }

//     //     return shippingCharge;
//     // };


//     const calculateShippingCharge = (order) => {
//         // Safeguard for when 'items' might not be passed or is not an array
//         if (!order || !Array.isArray(order.order_items) || order.order_items.length === 0) {
//             throw new Error("Order items are missing or invalid.");
//         }
    
//         const specialStates = [
//             "North East", "J & K", "Ladakh", "Andaman & Nicobar", 
//             "Himachal Pradesh", "Sikkim", "Lakshadweep",
//             "Arunachal Pradesh", "Assam", "Manipur", "Meghalaya", 
//             "Mizoram", "Nagaland", "Tripura"
//         ];
    
//         const billingState = order.billing_state;
//         const paymentMethod = order.payment_method;
//         const sellingPrice = order.sub_total;
//         const items = order.order_items; // Assuming items is an array with the order items
//         const itemWeight = 0.25; // Each item weighs 0.25 kg
    
//         let shippingCharge = 0;
    
//         // If the billing state is a special state, set specific shipping charges based on payment method
//         if (billingState && specialStates.includes(billingState)) {
//             if (paymentMethod === "Prepaid") {
//                 shippingCharge = 85;
//             } else if (paymentMethod === "COD") {
//                 shippingCharge = 85;
    
//                 // Add 2% of selling price to shipping if selling price > 2000
//                 if (sellingPrice > 2000) {
//                     shippingCharge += sellingPrice * 0.025;
//                 }
//                 else {
//                     // If the sub_total is less than or equal to 2000, add 50 to the shipping charge
//                     shippingCharge += 50;
//                 }
//             }
//         } else {
//             // Default shipping charges based on payment method for non-special states
//             if (paymentMethod === "Prepaid") {
//                 shippingCharge = 65;
//             } else if (paymentMethod === "COD") {
//                 shippingCharge = 65;
    
//                 // Add 2.5% of selling price to shipping if selling price > 2000
//                 if (sellingPrice > 2000) {
//                     shippingCharge += sellingPrice *  0.025;
//                 }
//                 else {
//                     // If the sub_total is less than or equal to 2000, add 50 to the shipping charge
//                     shippingCharge += 50;
//                 }
//             }
//         }
    
//         // Calculate the number of "weight groups" (each group is 0.5 kg, which is 2 items)
//         const totalItems = items.length;
//         const weightGroups = Math.ceil(totalItems * itemWeight / 0.5); // Number of 0.5 kg groups
    
//         // Increase shipping charge based on the number of weight groups
//         // Every group (0.5kg) increases the shipping price
//         shippingCharge *= weightGroups;
    
//         return shippingCharge;
//     };
    
    
    
//     // Function to calculate total cost
//     const calculateTotalCost = (order) => {
//         // Ensure that order_items exists and is an array
//         if (!Array.isArray(order.order_items)) {
//             console.error('order_items is not an array or is undefined');
//             return 0; // Return 0 if there's an error
//         }
    
//         const shippingCharge = calculateShippingCharge(order);
    
//         // Use reduce to calculate the total item cost, ensuring the item data is valid
//         const totalItemCost = order.order_items.reduce((sum, item) => {
//             // Check if item.design exists and has a totalPrice
//             if (item.design && typeof item.design.totalPrice === 'number') {
//                 const itemTotal = (item.design.totalPrice || 0) * item.units;
//                 return sum + itemTotal;
//             } else {
//                 // If no valid item design or totalPrice, log the issue and return the sum unchanged
//                 console.warn('Invalid item or missing totalPrice in item:', item);
//                 return sum;
//             }
//         }, 0);
    
//         return totalItemCost + shippingCharge;
//     };
    

//     const calculateCostWithGST = (order) => {
//         const totalCost = calculateTotalCost(order); // Use the previously defined total cost function
//         const gstAmount = totalCost * 0.05; // 5% GST
//         return totalCost + gstAmount; // Total cost + GST
//     };


//     const getCurrentBalance = async () => {
//         try {
//             // Fetch the wallet document for the current user
//             const walletRef = firestoreDoc(db, 'users', auth.currentUser.uid, 'wallet', 'walletDetails');
//             const walletSnap = await getDoc(walletRef);
            
//             // Check if the wallet document exists
//             if (!walletSnap.exists()) {
//                 toast.error('You need to recharge', { onClose: () => setIsModalOpen(true) });
//                 return null;  // Return null if the wallet document doesn't exist
//             }
    
//             // Extract the current balance from the wallet document
//             const walletData = walletSnap.data();
//             const currentBalance = walletData.balance;
            
//             // Return the current balance
//             return currentBalance;
    
//         } catch (error) {
//             console.error('Error fetching current balance:', error);
//             toast.error('Error fetching balance');
//             return null;  // Return null if there's an error fetching the data
//         }
//     };
    



//         const handleImageClick = (imageSrc) => {
//             setSelectedImage(imageSrc); // Set the clicked image as the selected one
//         };

//         const handleCloseModal = () => {
//             setSelectedImage(null); // Close the modal by clearing the selected image
//         };

//         useEffect(() => {
//             fetchOrders();
//         }, );

//         if (loading) {
//             return <div>Loading orders...</div>;
//         }


//         const totalPages = Math.ceil(orders.length / ordersPerPage);
//         const startIndex = (currentPage - 1) * ordersPerPage;
//         const paginatedOrders = orders.slice(startIndex, startIndex + ordersPerPage);
        
//         const confirmOrder = async () => {
//             try {
//                 // Initialize references
//                 const walletRef = firestoreDoc(db, 'users', auth.currentUser.uid, 'wallet', 'walletDetails');
        
//                 // Fetch the user's wallet details
//                 const walletSnap = await getDoc(walletRef);
//                 if (!walletSnap.exists()) {
//                     toast.error('You need to recharge', { onClose: () => setIsModalOpen(true) });
//                     return;
//                 }
        
//                 const walletData = walletSnap.data();
//                 const currentBalance = walletData.balance;
        
//                 // Check if the balance is sufficient
//                 if (currentBalance < totalCost) {
//                     toast.error('Insufficient balance. Recharge the amount.', { onClose: () => setIsModalOpen(true) });
//                     return;
//                 }
        
//                 // Fetch shop data (brand details) from Firestore
//                 const userRef = firestoreDoc(db, 'users', user.uid); // Assuming `shopId` is available
//                 const userSnap = await getDoc(userRef);
//                 if (!userSnap.exists()) {
//                     toast.error('Shop information not found.');
//                     return;
//                 }
//                 const userData = userSnap.data();

//                 // const brandingRef = collection(db, 'users', auth.currentUser.uid, 'branding');
//                 // const brandingSnap = await getDocs(brandingRef);
//                 // const brandingItems = brandingSnap.docs.map(doc => ({ brandingId: doc.id, ...doc.data() }));
                
//                 // // Loop through selected order items and reduce the quantity
//                 // for (let item of selectedOrder.order_items) {
//                 //     const orderedQuantity = item.units;
                
//                 //     for (let brandingItem of brandingItems) {
//                 //         const currentQuantity = brandingItem.quantity;
                
//                 //         // Check if there is enough stock
//                 //         if (currentQuantity >= orderedQuantity) {
//                 //             // Reduce the quantity in Firestore
//                 //             const itemRef = firestoreDoc(db, 'users', auth.currentUser.uid, 'branding', brandingItem.brandingId);
//                 //             await updateDoc(itemRef, { quantity: currentQuantity - orderedQuantity });
                
//                 //             toast.success(`Item quantity updated!`);
//                 //             break;  // Exit loop after successfully updating one item
//                 //         } else {
//                 //             toast.error(`Insufficient stock for item with branding ID ${brandingItem.brandingId}`);
//                 //             return; // Stop processing if stock is insufficient
//                 //         }
//                 //     }
//                 // }
//                 const brandingRef = collection(db, 'users', auth.currentUser.uid, 'branding');
//                 const brandingSnap = await getDocs(brandingRef);
//                 const brandingItems = brandingSnap.docs.map(doc => ({ brandingId: doc.id, ...doc.data() }));
                
//                 // Loop through selected order items and calculate total ordered quantity
//                 let totalOrderedQuantity = selectedOrder.order_items.reduce((sum, item) => sum + item.units, 0);
                
//                 // Loop through branding items to reduce stock
//                 for (let item of selectedOrder.order_items) {
//                     let remainingQuantity = totalOrderedQuantity; // Track remaining units to reduce
                    
//                     for (let brandingItem of brandingItems) {
//                         const currentQuantity = brandingItem.quantity;
                
//                         // Check if the current branding item has enough stock
//                         if (currentQuantity >= remainingQuantity) {
//                             // Reduce the stock for the full ordered quantity
//                             const itemRef = firestoreDoc(db, 'users', auth.currentUser.uid, 'branding', brandingItem.brandingId);
//                             await updateDoc(itemRef, { quantity: currentQuantity - remainingQuantity });
//                             break;  // Stop once the ordered quantity has been fulfilled
//                         } else if (currentQuantity > 0) {
//                             // If not enough stock, reduce what we can
//                             const itemRef = firestoreDoc(db, 'users', auth.currentUser.uid, 'branding', brandingItem.brandingId);
//                             await updateDoc(itemRef, { quantity: 0 });
//                             remainingQuantity -= currentQuantity;  // Deduct the available stock
//                         }
//                     }
//                 }
                





//                 const ordersRef = firestoreDoc(db, 'users', user.uid, 'orders', selectedOrder.id); // Assuming `shopId` is available
//                 const ordersSnap = await getDoc(ordersRef);
//                 if (!ordersSnap.exists()) {
//                     toast.error('Shop information not found.');
//                     return;
//                 }
//                 const ordersData = ordersSnap.data();


//                 const CustomerInfo = {
//                     Name: ordersData.billing_customer_name || 'Default Brand Name',
//                     address: ordersData.billing_address || 'N/A',
//                     city: ordersData.billing_city || 'N/A',         
//                     state: ordersData.billing_state || 'N/A',     
//                     pincode:ordersData.billing_pincode || 'N/A',
//                     country: ordersData.billing_country || 'N/A',
//                 };
        

                
//                 const brandInfo = {
//                     brandName: userData.brandName || 'Default Brand Name',
//                     address: userData.address || 'N/A',
//                     state: userData.state || 'N/A',         
//                     pinCode: userData.pincode || 'N/A',     
//                     gstNumber: userData.gstNumber || 'N/A',
//                     contact: userData.phoneNumber || 'N/A',
//                 };
        
//                 const mainInfo = {
//                     name: 'UNITEE STUDIOS PRIVATE LIMITED',
//                 };
        
//                 const invoiceDate = new Date().toLocaleDateString('en-GB', {
//                     day: '2-digit',
//                     month: 'short',
//                     year: '2-digit',
//                 });
        

//                 const storageRef = ref(Storage, 'invoices/');

//                 // Get the list of items (invoices) in the 'invoices/' folder
//                 const listResult = await listAll(storageRef);
//                 let nextInvoiceNumber = 1;  // Default to 1 if no invoices exist
                
//                 if (listResult.items.length > 0) {
//                     // Extract the numeric part of each file name (e.g., 'invoice_001.pdf')
//                     const invoiceNumbers = listResult.items
//                         .map(item => item.name.match(/invoice_(\d+)\.pdf$/))  // Adjusting regex to capture invoice number
//                         .filter(match => match)  // Filter out invalid names
//                         .map(match => parseInt(match[1], 10));  // Parse the numeric part of the invoice number
                
//                     // Find the highest invoice number and increment it
//                     if (invoiceNumbers.length > 0) {
//                         nextInvoiceNumber = Math.max(...invoiceNumbers) + 1;  // Increment the highest invoice number by 1
//                     }
//                 }
                
//                 // Format the invoice number (e.g., 'UC/2024-25/001')
//                 const invoiceNumber = `UC/2024-25/${String(nextInvoiceNumber).padStart(3, '0')}`;
                
//                 // Rest of the code for creating the invoice and uploading it...
                

//                 const doc = new jsPDF();

//                 // Header Section
//                 doc.setFontSize(20);
        
//                 doc.text('TAX INVOICE', 150, 15, null, null, 'center');
                
//                 // Company Information
//                 doc.setFontSize(10);
//                 doc.setFont("Helvetica", "normal");
//                 doc.text("UNITEE STUDIOS PRIVATE LIMITED", 10, 20);
//                 doc.text("Maharashtra, India", 10, 25);
//                 doc.text("GSTIN 27AADCU3575G1Z5", 10, 30);
//                 doc.text("rajprit@uniteeculture.com", 10, 35);
//                 doc.text("1ST FLOOR, PLOT 304/E/, 3, WAKHARIA BUILDING Vithalbhai Patel Road", 10, 40);
//                 doc.text("MUMBAI", 10, 45);
                
//                 // Invoice Details
//                 doc.text("Invoice Details", 150, 25, null, null, 'center');
//                 doc.setFontSize(10);
//                 doc.text(`Invoice Date: ${invoiceDate}`, 140, 30);
//                 doc.text(`Invoice Number: ${invoiceNumber}`, 140, 35);
//                 // doc.text(`Terms: Due On Receipt`, 140, 40);
//                 // doc.text("Due Date: 11/11/2024", 140, 45);
//                 // doc.text("Place of Supply: Maharashtra (27)", 140, 50);
                
//                 // Billing and Shipping Information
//                 doc.text("Bill To", 10, 60);
//                 doc.text(`Name: ${brandInfo.brandName}`, 10, 65);
//                 doc.text(`Address: ${brandInfo.address}`, 10, 70);
//                 doc.text(`State: ${brandInfo.state}`, 10, 75);
//                 doc.text(`Pin Code: ${brandInfo.pinCode}`, 10, 80);
//                 doc.text(`GST Number: ${brandInfo.gstNumber}`, 10, 85);
                
//                 doc.text("Ship To", 140, 60);
//                 doc.text(`Name: ${CustomerInfo.Name}`, 140, 65);
//                 doc.text(`Address: ${CustomerInfo.address}`, 140, 70);
//                 doc.text(`City: ${CustomerInfo.city}`, 140, 75);
//                 doc.text(`State: ${CustomerInfo.state}`, 140, 80);           
//                 doc.text(`Pin Code: ${CustomerInfo.pincode}`, 140, 85);


//                 // doc.text("Ship To", 140, 60);
//                 // doc.text(`Name: ${billingInfo.name}`, 140, 65);
//                 // doc.text(`Address: ${billingInfo.address}`, 140, 70);
//                 // doc.text(`City: ${billingInfo.city}`, 140, 75);
//                 // doc.text(`State: ${billingInfo.state}`, 140, 80);
//                 // doc.text(`Pin Code: ${billingInfo.pincode}`, 140, 85);
//                 // doc.text(`Country: ${billingInfo.country}`, 140, 90);
                
//     const itemsTableY = 90;
//     autoTable(doc, {
//         startY: itemsTableY,
//         head: [
//             ['#', 'Item & Description', 'HSN/SAC', 'Qty', 'Rate', 
//              brandInfo.state === "Maharashtra" ? 'CGST %' : 'IGST %', 
//              brandInfo.state === "Maharashtra" ? 'CGST Amt' : 'IGST Amt', 
//              brandInfo.state === "Maharashtra" ? 'SGST %' : '', 
//              brandInfo.state === "Maharashtra" ? 'SGST Amt' : '', 
//              brandInfo.state !== "Maharashtra" ? 'IGST %' : '', 
//              brandInfo.state !== "Maharashtra" ? 'IGST Amt' : '', 
//              'Amount'
//             ]
//         ],
//         body: selectedOrder.order_items.map((item, index) => {
//             // Calculate the individual item price (including shipping)
//             const itemPrice = (item.design?.totalPrice || 0) * item.units + shippingCharge;  // Item price + shipping
//             let cgstAmt = 0;
//             let sgstAmt = 0;
//             let igstAmt = 0;
//             let taxAmount = 0;
    
//             if (brandInfo.state === "Maharashtra") {
//                 // **CGST and SGST for Maharashtra**
//                 cgstAmt = (itemPrice * 0.025).toFixed(2);  // 2.5% CGST
//                 sgstAmt = (itemPrice * 0.025).toFixed(2);  // 2.5% SGST
//                 taxAmount = (parseFloat(cgstAmt) + parseFloat(sgstAmt)).toFixed(2);  // Total CGST + SGST
//             } else {
//                 // **IGST for other states**
//                 igstAmt = (itemPrice * 0.05).toFixed(2);  // 5% IGST
//                 taxAmount = igstAmt;  // Only IGST
//             }
    
//             // Calculate total amount per item (including tax)
//             const amount = (itemPrice + parseFloat(cgstAmt || igstAmt) + parseFloat(sgstAmt || 0)).toFixed(2);
    
//             return [
//                 index + 1,
//                 item.design?.designName || 'Unknown',
//                 '610910',  // Example HSN code
//                 item.units,
//                 itemPrice.toFixed(2),
//                 // **Display CGST and SGST for Maharashtra, IGST for others**
//                 brandInfo.state === "Maharashtra" ? '2.5%' : '', 
//                 brandInfo.state === "Maharashtra" ? cgstAmt : '',
//                 brandInfo.state === "Maharashtra" ? '2.5%' : '', 
//                 brandInfo.state === "Maharashtra" ? sgstAmt : '',
//                 brandInfo.state !== "Maharashtra" ? '5%' : '', 
//                 brandInfo.state !== "Maharashtra" ? igstAmt : '',
//                 amount,
//             ];
//         }),
//         theme: 'grid',
//         styles: { fontSize: 10 },
//     });

                
//                 // Tax and Summary Table
//                 let totalTaxableValue = 0;
//                 let totalCGST = 0;
//                 let totalSGST = 0;
//                 let totalIGST = 0;
                
//                 selectedOrder.order_items.forEach(item => {
//                     // Calculate the item price (including shipping)
//                     const itemPrice = (item.design?.totalPrice || 0) * item.units + shippingCharge;  // Item price + shipping
//                     const taxableValue = itemPrice * item.units;
//                     totalTaxableValue += taxableValue;
                
//                     // **Apply CGST, SGST for Maharashtra and IGST for others**
//                     if (brandInfo.state === "Maharashtra") {
//                         totalCGST += taxableValue * 0.025;  // **CGST**
//                         totalSGST += taxableValue * 0.025;  // **SGST**
//                     } else {
//                         totalIGST += taxableValue * 0.05;  // **IGST**
//                     }
//                 });
                
//                 const taxSummaryY = doc.autoTable.previous.finalY + 10;
//                 autoTable(doc, {
//                     startY: taxSummaryY,
//                     head: [['HSN/SAC', 'Taxable Value', 
//                         brandInfo.state === "Maharashtra" ? 'CGST %' : 'IGST %', 
//                         brandInfo.state === "Maharashtra" ? 'CGST Amt' : 'IGST Amt', 
//                         brandInfo.state === "Maharashtra" ? 'SGST %' : '', 
//                         brandInfo.state === "Maharashtra" ? 'SGST Amt' : '', 
//                         brandInfo.state !== "Maharashtra" ? 'IGST %' : '', 
//                         brandInfo.state !== "Maharashtra" ? 'IGST Amt' : '',
//                         'Total Tax Amount']],
//                     body: [
//                         ['610910', totalTaxableValue.toFixed(2),
//                             // **Display CGST/SGST/IGST based on state**
//                             brandInfo.state === "Maharashtra" ? '2.5%' : '', 
//                             brandInfo.state === "Maharashtra" ? totalCGST.toFixed(2) : '',
//                             brandInfo.state === "Maharashtra" ? '2.5%' : '', 
//                             brandInfo.state === "Maharashtra" ? totalSGST.toFixed(2) : '',
//                             brandInfo.state !== "Maharashtra" ? '5%' : '', 
//                             brandInfo.state !== "Maharashtra" ? totalIGST.toFixed(2) : '',
//                             (totalCGST + totalSGST + totalIGST).toFixed(2)]
//                     ],
//                     theme: 'grid',
//                     styles: { fontSize: 10 },
//                 });
            
//             // Final Amount Summary
//             const totalAmount = totalTaxableValue + totalCGST + totalSGST + totalIGST;
//             const finalAmountY = doc.autoTable.previous.finalY + 10;
//             // doc.text(`Amount Chargeable (in words): Indian Rupee ${numberToWords(totalAmount)} Only`, 10, finalAmountY);
//             doc.text(`Total: ${totalAmount.toFixed(2)}`, 150, finalAmountY + 5);
//             doc.text(`Balance Due: ${totalAmount.toFixed(2)}`, 150, finalAmountY + 10);
            
//             // Footer Notes
//             doc.text("Thanks for your business.", 10, finalAmountY + 25);
//             doc.text("Authorized Signature", 150, finalAmountY + 25);
            

//             const invoiceFileName = `invoice_${nextInvoiceNumber}.pdf`;  // Updated to use nextInvoiceNumber
//             const pdfBlob = doc.output('blob');  // Generate the PDF blob as usual
    
//             // Upload the invoice to Firebase Storage
//             const invoiceRef = ref(Storage, `invoices/${invoiceFileName}`);
//             const uploadTask = uploadBytesResumable(invoiceRef, pdfBlob);
    
//             // Observe the upload task
//             uploadTask.on(
//                 'state_changed',
//                 snapshot => {
//                     // You can handle upload progress here (e.g., using a progress bar)
//                     const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//                     console.log(`Upload is ${progress}% done`);
//                 },
//                 error => {
//                     console.error('Error uploading invoice:', error);
//                     toast.error('Error uploading invoice');
//                 },
//                 async () => {
//                     try {
//                         // After upload is complete, get the download URL
//                         const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
//                         console.log('Invoice uploaded, download URL:', downloadURL);
    
//                         // Fetch order data for Shiprocket
//                         const orderRef = firestoreDoc(db, 'users', user.uid, 'orders', selectedOrder.id);
//                         const orderSnapshot = await getDoc(orderRef);
//                         const orderData = { id: selectedOrder.id, ...orderSnapshot.data() };

//                         const orderWithPickupLocation = await Promise.all(
//                             orderData.order_items.map(async (item) => {
//                                 const designData = await fetchDesignDetailsBySKU(item.sku);
//                                 return { ...item, design: designData };
//                             })
//                         );
                
//                         // Create the updated order object with pickup location and other necessary details
//                         const updatedOrder = {
//                             ...orderData,
//                             user_uid: auth.currentUser.uid,
//                             order_items: orderWithPickupLocation,
//                             pickup_location: orderWithPickupLocation[0]?.design?.pickupLocation || 'Default Location', // Set the pickup location for the order
//                         };
    
//                         // Create order on Shiprocket
//                         console.log("Creating order on Shiprocket...");
//                         const response = await axios.post('https://ufb-1.onrender.com/create-shiprocket-order', updatedOrder, {
//                             headers: {
//                                 'Content-Type': 'application/json',
//                             },
//                         });
//                         console.log('Order created on Shiprocket successfully:', response.data);
    
//                         // Deduct the total cost from the current balance
//                         const updatedBalance = currentBalance - totalCost;
    
//                         // Update wallet balance
//                         console.log("Updating wallet balance...");
//                         await updateDoc(walletRef, { balance: updatedBalance });
//                         console.log("Wallet balance updated successfully.");
    
//                         // Record the transaction
//                         const transactionsRef = collection(walletRef, 'transactions');
//                         const transactionData = {
//                             type: selectedOrder.id,
//                             amount: totalCost,
//                             balanceBefore: currentBalance,
//                             balanceAfter: updatedBalance,
//                             // productName: selectedOrder.order_items.map(item => item.design?.designName).join(', '),
//                             timestamp: Timestamp.now(),
//                         };
//                         console.log("Recording transaction data:", transactionData);
//                         await addDoc(transactionsRef, transactionData);
//                         console.log("Transaction recorded successfully.");
    
//                         // Mark the order as confirmed and paid
//                         await updateDoc(orderRef, {
//                             UniteeTotalPrice: totalCost,
//                             status: 'confirmed',
//                             isPaid: true, // Update the order to indicate it's paid
//                             invoiceUrl: downloadURL // Store the invoice URL
//                         });
//                         console.log("Order status updated successfully.");
    
//                         // Mark the order as confirmed
//                         setConfirmedOrders([...confirmedOrders, selectedOrder.id]);
    
//                         toast.success('Order confirmed and invoice generated!');
//                         setShowConfirmModal(false); // Close modal
//                         fetchOrders(); // Refresh the orders
//                     } catch (err) {
//                         console.error('Error handling completed upload:', err);
//                         toast.error('Error handling completed upload.');
//                     }
//                 }
//             );
//         } catch (error) {
//             console.error('Error in confirmOrder function:', error);
    
//             // Handle error response specifically for Shiprocket
//             if (error.response) {
//                 console.error('Error response from Shiprocket:', error.response.data);
//                 toast.error(`Error: ${error.response.data.error || 'An unexpected error occurred.'}`);
//             } else {
//                 console.error('Error confirming order:', error);
//                 toast.error('An error occurred while confirming the order.');
//             }
//         }
//     };


    
   

//        let totalCost = 0;
//     let shippingCharge = 65;

//     if (showConfirmModal && selectedOrder) {
//         totalCost = calculateCostWithGST(selectedOrder);
//     }

//     return (
//         <div className="orders-page">
//             <Header />
//             <div className="orders-container">
//                 <Sidebar />
//                 <div className="main-content">
//                     <div className='ordersp'>Confirm your orders, and we'll start printing right away!</div>
//                     <h1>Orders</h1>

//                     {showConfirmModal && selectedOrder && (
                      
                      

//                         <div className="modals">
//                             <div className="modal-contents">
//                                 <h3>Confirm Order</h3>
//                                 <p>
//                                     Are you sure you want to confirm the order for{' '}
//                                     {selectedOrder.order_items.map(item => item.design?.designName).join(', ')}?
//                                 </p>
//                                 <p>
//                                     Current Balance: ₹{currentBalance.toFixed(2)}
//                                 </p>
                                
//                                 <p>Total Cost: ₹{calculateCostWithGST(selectedOrder)}</p>
//                                 <p>Balance After Deduction: ₹{currentBalance - calculateCostWithGST(selectedOrder)}</p>
//                                 <button onClick={confirmOrder}>Yes, Confirm</button>
//                                 <button onClick={() => setShowConfirmModal(false)}>Cancel</button>
//                             </div>
//                         </div>
//                     )}

//                     {showCancelModal && (
//                         <div className="modals">
//                             <div className="modal-contents">
//                                 <h3>Cancel Order</h3>
//                                 <p>Are you sure you want to cancel this order?</p>
//                                 <button onClick={confirmCancelOrder}>Yes, Cancel</button>
//                                 <button onClick={() => setShowCancelModal(false)}>No</button>
//                             </div>
//                         </div>
//                     )}

//                     <ToastContainer />
//                     {isModalOpen && <Wallet />}
//                     <table className="orders-table">
//                         <thead>
//                             <tr>
//                                 <th>Order ID</th>
//                                 <th className='hideee'>Unitee Order ID</th>
//                                 <th>Design Name</th>
//                                 {/* <th>SKU</th> */}
//                                 <th className='hideee'>Quantity</th>
//                                 <th className='hideee'>GSM</th>
//                                 <th className='hideee'>Selling Price</th>
//                                 <th className='hideee'>Items Ordered</th>
//                                 <th>Front Image</th>
//                                 <th>Back Image</th>
//                                 <th className='hideee'>Base Cost</th>
//                                 <th className='hideee'>Print Cost</th>
//                                 <th className='hideee'>Shipping Cost</th>
//                                 <th>Total Cost</th>
//                                 <th>Actions</th>
//                             </tr>
//                         </thead>
//                         <tbody>
                            
//                             {paginatedOrders.map((order) => (
//                                 <tr key={order.id}>
//                                     <td>{order.Storeorder_id || 'NA'}</td>
//                                     <td className='hideee'>{order.id}</td> 
//                                     <td>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>{item.design?.designName || 'Unknown'}</p>
//                                         ))}
//                                     </td>
//                                     {/* <td>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>{item.sku}</p>
//                                         ))}
//                                     </td> */}
//                                     <td className='hideee'>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>{item.units}</p>
//                                         ))}
//                                     </td>
//                                     <td className='hideee'>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>{item.design?.productGSM || 'N/A'}</p>
//                                         ))}
//                                     </td>
//                                     <td className='hideee'> 
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>{item.selling_price}</p>
//                                         ))}
//                                     </td>
//                                     <td className='hideee'>{order.order_items.reduce((sum, item) => sum + item.units, 0)}</td>
//                                     <td>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>
//                                                 {item.design?.frontImage ? (
//                                                     <img src={item.design.frontImage} alt="Front Design" style={{ width: '50px', height: 'auto' }} onClick={() => handleImageClick(item.design.frontImage)} />
//                                                 ) : (
//                                                     'N/A'
//                                                 )}
//                                             </p>
//                                         ))}
//                                     </td>
//                                     <td>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>
//                                                 {item.design?.backImage ? (
//                                                     <img src={item.design.backImage} alt="Back Design" style={{ width: '50px', height: 'auto' }} onClick={() => handleImageClick(item.design.backImage)} />
//                                                 ) : (
//                                                     'N/A'
//                                                 )}
//                                             </p>
//                                         ))}
//                                     </td>
//                                     <td className='hideee'>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>{item.design?.baseCost || 'N/A'}</p>
//                                         ))}
//                                     </td>
//                                     <td className='hideee'>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>{item.design?.PrintCost || 'N/A'}</p>
//                                         ))}
//                                     </td>
//                                     <td className='hideee'>
//                                     {calculateShippingCharge(order)}
//                                         {/* {order.order_items.map((item) => (
//                                             <p key={item.sku}>{calculateShippingCharge(order)}</p> // Static shipping cost
//                                         ))} */}
//                                     </td>
//                                     <td>
//                                         {/* {order.order_items.reduce((sum, item) => sum + (item.design?.totalPrice || 0) * item.units + shippingCharge, 0)}  */}
//                                         {calculateTotalCost(order)}
//                                     </td>
//                                     <td>
//                                         <button className="confirm-button" onClick={() => handleConfirmOrder(order.id)} disabled={order.isPaid}>
//                                             {order.isPaid ? 'Paid' : 'Confirm'}
//                                         </button>
//                                         <button className="cancel-button" onClick={() => handleCancelOrderClick(order.id)}>Cancel</button>
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                     <div className="pagination-controls">
//                         <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
//                             Previous
//                         </button>
//                         <span>Page {currentPage} of {totalPages}</span>
//                         <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
//                             Next
//                         </button>
//                     </div>
//                     <ImageModal imageSrc={selectedImage} onClose={handleCloseModal} />
//                 </div>
//             </div>
//         </div>
//     );
// };
// export default OrdersPage;