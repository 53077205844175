// import React, { useState, useEffect } from 'react';
// import { db, Storage, auth } from '../firebase';
// import { doc, getDoc, updateDoc, collection, addDoc, query, where, getDocs, Timestamp } from 'firebase/firestore';
// import { toast } from 'react-toastify';
// import Header from '../components/Header';
// import Sidebar from '../components/Sidebar';
// import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

// const Branding = () => {
//   const [image, setImage] = useState(null);
//   const [quantity, setQuantity] = useState(0); // Default quantity set to 0
//   const [price, setPrice] = useState(0); // Default price is also 0 initially
//   const [walletBalance, setWalletBalance] = useState(0);
//   const [isImageSelected, setIsImageSelected] = useState(false); // Flag for image selection
//   const [confirmBalance, setConfirmBalance] = useState(0); // For updating the wallet balance after deduction
//   const [showConfirmModal, setShowConfirmModal] = useState(false); // Modal visibility flag
//   const [orders, setOrders] = useState([]); // State to store previous orders
  
//   const user = auth.currentUser;
//   if (!user) {
//     throw new Error("User not logged in");
//   }

//   // Fetch wallet balance on component mount or when userId changes
//   useEffect(() => {
//     const fetchWalletBalance = async () => {
//       if (!user.uid) return;

//       try {
//         const walletRef = doc(db, 'users', user.uid, 'wallet', 'walletDetails');
//         const walletSnap = await getDoc(walletRef);
//         if (walletSnap.exists()) {
//           const walletData = walletSnap.data();
//           setWalletBalance(walletData.balance);
//         } else {
//           toast.error('Wallet not found. Please recharge.');
//         }
//       } catch (error) {
//         console.error('Error fetching wallet balance:', error);
//         toast.error('Error fetching wallet balance.');
//       }
//     };

//     fetchWalletBalance();
//   }, [user.uid]);

//   // Fetch user's previous orders for neck labels
//   useEffect(() => {
//     const fetchOrders = async () => {
//       if (!user.uid) return;

//       try {
//         const ordersRef = collection(db, 'users', user.uid, 'branding');
//         const q = query(ordersRef, where("timestamp", ">=", Timestamp.fromDate(new Date(0)))); // Query to fetch all orders
//         const querySnapshot = await getDocs(q);
        
//         const userOrders = querySnapshot.docs.map(doc => doc.data());
//         setOrders(userOrders);
//       } catch (error) {
//         console.error('Error fetching orders:', error);
//         toast.error('Error fetching previous orders.');
//       }
//     };

//     fetchOrders();
//   }, [user.uid]);

//   // Increase quantity by 25
//   const increaseQuantity = () => {
//     setQuantity(prevQuantity => {
//       const newQuantity = prevQuantity + 25;
//       setPrice(newQuantity * 5); // Update price based on quantity
//       return newQuantity;
//     });
//   };

//   // Decrease quantity by 25
//   const decreaseQuantity = () => {
//     if (quantity > 0) {
//       setQuantity(prevQuantity => {
//         const newQuantity = prevQuantity - 25;
//         setPrice(newQuantity * 5); // Update price based on quantity
//         return newQuantity;
//       });
//     }
//   };

//   const handleImageUpload = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setImage(reader.result); // Set uploaded image as base64 string
//         setIsImageSelected(true);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   // Handle confirm button click (order submission logic)
//   const handleConfirm = async () => {
//     const totalAmount = price;

//     // Check if the wallet balance is sufficient
//     if (walletBalance < totalAmount) {
//       toast.error('Insufficient balance. Please recharge your wallet.');
//       return;
//     }

//     // Step 1: Upload image to Firebase Storage
//     if (image) {
//       try {
//         const response = await fetch(image);
//         const blob = await response.blob();

//         // Create a reference to Firebase Storage
//         const fileRef = ref(Storage, `branding_images/${user.uid}_${Date.now()}.png`);
        
//         // Upload the image using uploadBytesResumable
//         const uploadTask = uploadBytesResumable(fileRef, blob);
        
//         uploadTask.on('state_changed', 
//           (snapshot) => {
//             // Optionally add a progress bar or log progress
//           }, 
//           (error) => {
//             toast.error('Error uploading image: ' + error.message);
//           }, 
//           async () => {
//             // Once upload is complete, we can get the download URL
//             const imageURL = await getDownloadURL(uploadTask.snapshot.ref);  // This is correct for Firebase v9+ modular SDK

//             // Step 2: Store branding details in Firestore with image URL
//             const brandingRef = collection(db, 'users', user.uid, 'branding');
//             const brandingData = {
//               quantity,
//               price,
//               Neck_label: imageURL, // Store the image URL
//               timestamp: Timestamp.now(),
//             };

//             await addDoc(brandingRef, brandingData);

//             // Step 3: Update the wallet balance after successful data storage
//             const updatedBalance = walletBalance - totalAmount;
//             const walletRef = doc(db, 'users', user.uid, 'wallet', 'walletDetails');
//             await updateDoc(walletRef, { balance: updatedBalance });

//             // Step 4: Record the transaction in Firestore
//             const transactionsRef = collection(walletRef, 'transactions');
//             const transactionData = {
//               type: 'Neck Labels',
//               amount: totalAmount,
//               balanceBefore: walletBalance,
//               balanceAfter: updatedBalance,
//               timestamp: Timestamp.now(),
//             };

//             await addDoc(transactionsRef, transactionData);

//             toast.success('Neck Labels purchased successfully!');
//             setShowConfirmModal(false); // Close confirmation modal
//           }
//         );
//       } catch (error) {
//         console.error('Error processing the order:', error);
//         toast.error('Error submitting the order. Please try again.');
//       }
//     } else {
//       toast.error('No image selected. Please upload an image.');
//     }
//   };

//   // Remove the uploaded image
//   const removeImage = () => {
//     setImage(null);
//     setIsImageSelected(false);
//   };

//   return (

//     <div className="contact-us">
//     <Header />
//     <div className="contact-container">
//       <Sidebar />
//       <div className="main-content">
//     {/* <div style={{ textAlign: 'center', marginTop: '50px' }}> */}

//     <div>
//             <ul>
//               {orders.length > 0 ? (
//                 orders.map((order, index) => (
//                   <li key={index}>
//                     {/* <strong>Quantity:</strong>                      */}
//                     {order.quantity} 
//                   </li>
//                 ))
//               ) : (
//                 <p>No previous orders found.</p>
//               )}
//             </ul>
//           </div>


//       <div
//         style={{
//           width: '200px',
//           height: '200px',
//           border: '2px solid #000',
//           margin: '0 auto',
//           position: 'relative',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           backgroundColor: '#f8f8f8',
//         }}
//       >
//         {!isImageSelected ? (
//           <>
//             {/* Upload Icon */}
//             <label
//               style={{
//                 fontSize: '20px',
//                 cursor: 'pointer',
//                 color: '#888',
//                 textAlign: 'center',
//               }}
//             >
//               <i className="fas fa-upload"></i> Upload Image
//               <input
//                 type="file"
//                 accept="image/*"
//                 onChange={handleImageUpload}
//                 style={{
//                   position: 'absolute',
//                   top: 0,
//                   left: 0,
//                   width: '100%',
//                   height: '100%',
//                   opacity: 0,
//                   cursor: 'pointer',
//                 }}
//               />
//             </label>
//           </>
//         ) : (
//           <>
//             {/* Uploaded Image */}
//             <img
//               src={image}
//               alt="Uploaded"
//               style={{
//                 width: '100%',
//                 height: '100%',
//                 objectFit: 'contain',
//                 borderRadius: '8px',
//               }}
//             />
//             <div
//               style={{
//                 position: 'absolute',
//                 top: '10px',
//                 right: '10px',
//                 backgroundColor: 'rgba(0, 0, 0, 0.6)',
//                 color: '#fff',
//                 padding: '5px 10px',
//                 borderRadius: '5px',
//                 cursor: 'pointer',
//               }}
//               onClick={removeImage}
//             >
//               Remove
//             </div>
//           </>
//         )}
//       </div>

//       {/* Quantity Controls */}
//       <div style={{ marginTop: '20px' }}>
//         <div
//           style={{
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             fontSize: '18px',
//             backgroundColor: '#f0f0f0',
//             padding: '10px',
//             borderRadius: '10px',
//             boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
//           }}
//         >
//           <button
//             onClick={decreaseQuantity}
//             style={{
//               backgroundColor: '#ff6666',
//               border: 'none',
//               color: '#fff',
//               fontSize: '20px',
//               padding: '8px 12px',
//               cursor: 'pointer',
//               borderRadius: '5px',
//               marginRight: '15px',
//             }}
//           >
//             -
//           </button>
//           <span style={{ fontSize: '24px', fontWeight: 'bold', margin: '0 15px' }}>{quantity}</span>
//           <button
//             onClick={increaseQuantity}
//             style={{
//               backgroundColor: '#66ff66',
//               border: 'none',
//               color: '#fff',
//               fontSize: '20px',
//               padding: '8px 12px',
//               cursor: 'pointer',
//               borderRadius: '5px',
//               marginLeft: '15px',
//             }}
//           >
//             +
//           </button>
//         </div>

//         <h4 style={{ marginTop: '10px' }}>Total Price: ₹{price}</h4>
//       </div>

//       {/* Confirm Button */}
//       <div style={{ marginTop: '20px' }}>
//         <button
//           onClick={() => setShowConfirmModal(true)}
//           style={{
//             padding: '10px 20px',
//             fontSize: '16px',
//             backgroundColor: '#4CAF50',
//             color: '#fff',
//             border: 'none',
//             borderRadius: '5px',
//             cursor: 'pointer',
//           }}
//         >
//           Confirm
//         </button>
//       </div>

//       {/* Confirmation Modal */}
//       {showConfirmModal && (
//         <div
//           style={{
//             position: 'fixed',
//             top: '50%',
//             left: '50%',
//             transform: 'translate(-50%, -50%)',
//             padding: '20px',
//             background: '#fff',
//             border: '1px solid #ddd',
//             borderRadius: '8px',
//           }}
//         >
//           <h3>Are you sure you want to proceed?</h3>
//           {/* <p>Total Amount: ₹{(price + 50 + (price + 50) * 0.05).toFixed(2)}</p> */}
//           <p>Total Amount: ₹{price}</p>
//           <div>
//             <button
//               onClick={handleConfirm}
//               style={{
//                 padding: '10px 20px',
//                 backgroundColor: '#4CAF50',
//                 color: '#fff',
//                 border: 'none',
//                 borderRadius: '5px',
//               }}
//             >
//               Confirm
//             </button>
//             <button
//               onClick={() => setShowConfirmModal(false)}
//               style={{
//                 padding: '10px 20px',
//                 backgroundColor: '#f44336',
//                 color: '#fff',
//                 border: 'none',
//                 borderRadius: '5px',
//                 marginLeft: '10px',
//               }}
//             >
//               Cancel
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//     </div>
//     </div>
//   );
// };

// export default Branding;


// import React, { useState, useEffect } from 'react';
// import { db, Storage, auth } from '../firebase';
// import { doc, getDoc, updateDoc, collection, addDoc, query, where, getDocs, Timestamp } from 'firebase/firestore';
// import { toast } from 'react-toastify';
// import Header from '../components/Header';
// import Sidebar from '../components/Sidebar';
// import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
// import '../style/Branding.css'

// const Branding = () => {
//   const [image, setImage] = useState(null);
//   const [quantity, setQuantity] = useState(0);
//   const [price, setPrice] = useState(0);
//   const [walletBalance, setWalletBalance] = useState(0);
//   const [isImageSelected, setIsImageSelected] = useState(false);
//   const [orders, setOrders] = useState([]);
//   const [selectedOption, setSelectedOption] = useState(''); // 'neck-label' or 'packaging'

//   const user = auth.currentUser;
//   if (!user) {
//     throw new Error("User not logged in");
//   }

//   useEffect(() => {
//     const fetchWalletBalance = async () => {
//       if (!user.uid) return;

//       try {
//         const walletRef = doc(db, 'users', user.uid, 'wallet', 'walletDetails');
//         const walletSnap = await getDoc(walletRef);
//         if (walletSnap.exists()) {
//           const walletData = walletSnap.data();
//           setWalletBalance(walletData.balance);
//         } else {
//           toast.error('Wallet not found. Please recharge.');
//         }
//       } catch (error) {
//         console.error('Error fetching wallet balance:', error);
//         toast.error('Error fetching wallet balance.');
//       }
//     };

//     fetchWalletBalance();
//   }, [user.uid]);

//   useEffect(() => {
//     const fetchOrders = async () => {
//       if (!user.uid) return;

//       try {
//         const ordersRef = collection(db, 'users', user.uid, 'branding');
//         const q = query(ordersRef, where("timestamp", ">=", Timestamp.fromDate(new Date(0))));
//         const querySnapshot = await getDocs(q);

//         const userOrders = querySnapshot.docs.map(doc => doc.data());
//         setOrders(userOrders);
//       } catch (error) {
//         console.error('Error fetching orders:', error);
//         toast.error('Error fetching previous orders.');
//       }
//     };

//     fetchOrders();
//   }, [user.uid]);

//   const increaseQuantity = () => {
//     setQuantity(prevQuantity => {
//       const newQuantity = prevQuantity + 25;
//       setPrice(newQuantity * 5);
//       return newQuantity;
//     });
//   };

//   const decreaseQuantity = () => {
//     if (quantity > 0) {
//       setQuantity(prevQuantity => {
//         const newQuantity = prevQuantity - 25;
//         setPrice(newQuantity * 5);
//         return newQuantity;
//       });
//     }
//   };

//   const handleImageUpload = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setImage(reader.result);
//         setIsImageSelected(true);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleConfirm = async () => {
//     const totalAmount = price;

//     if (walletBalance < totalAmount) {
//       toast.error('Insufficient balance. Please recharge your wallet.');
//       return;
//     }

//     if (image) {
//       try {
//         const response = await fetch(image);
//         const blob = await response.blob();

//         const fileRef = ref(Storage, `branding_images/${user.uid}_${Date.now()}.png`);
//         const uploadTask = uploadBytesResumable(fileRef, blob);

//         uploadTask.on('state_changed', 
//           (snapshot) => {},
//           (error) => {
//             toast.error('Error uploading image: ' + error.message);
//           }, 
//           async () => {
//             const imageURL = await getDownloadURL(uploadTask.snapshot.ref);

//             const brandingRef = collection(db, 'users', user.uid, 'branding');
//             const brandingData = {
//               quantity,
//               price,
//               Neck_label: imageURL,
//               timestamp: Timestamp.now(),
//             };

//             await addDoc(brandingRef, brandingData);

//             const updatedBalance = walletBalance - totalAmount;
//             const walletRef = doc(db, 'users', user.uid, 'wallet', 'walletDetails');
//             await updateDoc(walletRef, { balance: updatedBalance });

//             const transactionsRef = collection(walletRef, 'transactions');
//             const transactionData = {
//               type: 'Neck Labels',
//               amount: totalAmount,
//               balanceBefore: walletBalance,
//               balanceAfter: updatedBalance,
//               timestamp: Timestamp.now(),
//             };

//             await addDoc(transactionsRef, transactionData);

//             toast.success('Neck Labels purchased successfully!');
//           }
//         );
//       } catch (error) {
//         console.error('Error processing the order:', error);
//         toast.error('Error submitting the order. Please try again.');
//       }
//     } else {
//       toast.error('No image selected. Please upload an image.');
//     }
//   };

//   const removeImage = () => {
//     setImage(null);
//     setIsImageSelected(false);
//   };

//   return (
//     <div className="contact-us">
//       <Header />
//       <div className="contact-container">
//         <Sidebar />
//         <div className="main-content">
//           {/* Two clickable boxes */}
//           <div className="options-container">
//             <div 
//               className={`option-box ${selectedOption === 'neck-label' ? 'active' : ''}`}
//               onClick={() => setSelectedOption('neck-label')}
//             >
//               Neck Label
//             </div>
//             <div 
//               className={`option-box ${selectedOption === 'packaging' ? 'active' : ''}`}
//               onClick={() => setSelectedOption('packaging')}
//             >
//               Packaging
//             </div>
//           </div>

//           {/* Neck Label Form */}
//           {selectedOption === 'neck-label' && (
//             <div className="branding-form">
//               <h3>Neck Label Design</h3>
//               <div className="image-upload-box">
//                 {!isImageSelected ? (
//                   <label className="upload-label">
//                     <i className="fas fa-upload"></i> Upload Image
//                     <input
//                       type="file"
//                       accept="image/*"
//                       onChange={handleImageUpload}
//                       className="image-input"
//                     />
//                   </label>
//                 ) : (
//                   <div className="image-preview">
//                     <img src={image} alt="Uploaded" className="uploaded-image" />
//                     <button className="remove-button" onClick={removeImage}>Remove</button>
//                   </div>
//                 )}
//               </div>
              
//               <div className="quantity-controls">
//                 <button onClick={decreaseQuantity} className="quantity-button">-</button>
//                 <span className="quantity">{quantity}</span>
//                 <button onClick={increaseQuantity} className="quantity-button">+</button>
//               </div>
              
//               <h4>Total Price: ₹{price}</h4>
              
//               <button onClick={handleConfirm} className="confirm-button">
//                 Confirm
//               </button>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Branding;


import React, { useState, useEffect } from 'react';
import { db, Storage, auth } from '../firebase';
import { doc, getDoc, updateDoc, collection, addDoc, query, where, getDocs, Timestamp, setDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import '../style/Branding.css'
import { FaFileUpload, FaUpload } from 'react-icons/fa';

const Branding = () => {
  const [image, setImage] = useState(null);
  const [quantity, setQuantity] = useState(0); // Default quantity set to 0
  const [price, setPrice] = useState(0); // Default price is also 0 initially
  const [walletBalance, setWalletBalance] = useState(0);
  const [isImageSelected, setIsImageSelected] = useState(false); // Flag for image selection
  const [confirmBalance, setConfirmBalance] = useState(0); // For updating the wallet balance after deduction
  const [showConfirmModal, setShowConfirmModal] = useState(false); // Modal visibility flag
  const [orders, setOrders] = useState([]); // State to store previous orders
  const [page, setPage] = useState('select'); // Page selector: 'select' or 'branding'
  
  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not logged in");
  }

  // Fetch wallet balance on component mount or when userId changes
  useEffect(() => {
    const fetchWalletBalance = async () => {
      if (!user.uid) return;

      try {
        const walletRef = doc(db, 'users', user.uid, 'wallet', 'walletDetails');
        const walletSnap = await getDoc(walletRef);
        if (walletSnap.exists()) {
          const walletData = walletSnap.data();
          setWalletBalance(walletData.balance);
        } else {
          toast.error('Wallet not found. Please recharge.');
        }
      } catch (error) {
        console.error('Error fetching wallet balance:', error);
        toast.error('Error fetching wallet balance.');
      }
    };

    fetchWalletBalance();
  }, [user.uid]);

  // Fetch user's previous orders for neck labels
  useEffect(() => {
    const fetchOrders = async () => {
      if (!user.uid) return;

      try {
        const ordersRef = collection(db, 'users', user.uid, 'branding');
        const q = query(ordersRef, where("timestamp", ">=", Timestamp.fromDate(new Date(0)))); // Query to fetch all orders
        const querySnapshot = await getDocs(q);
        
        const userOrders = querySnapshot.docs.map(doc => doc.data());
        setOrders(userOrders);
      } catch (error) {
        console.error('Error fetching orders:', error);
        toast.error('Error fetching previous orders.');
      }
    };

    fetchOrders();
  }, [user.uid]);

  // Increase quantity by 25
  const increaseQuantity = () => {
    setQuantity(prevQuantity => {
      const newQuantity = prevQuantity + 25;
      setPrice(newQuantity * 5); // Update price based on quantity
      return newQuantity;
    });
  };

  // Decrease quantity by 25
  const decreaseQuantity = () => {
    if (quantity > 0) {
      setQuantity(prevQuantity => {
        const newQuantity = prevQuantity - 25;
        setPrice(newQuantity * 5); // Update price based on quantity
        return newQuantity;
      });
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Set uploaded image as base64 string
        setIsImageSelected(true);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle confirm button click (order submission logic)
  // const handleConfirm = async () => {
  //   const totalAmount = price;

  //   // Check if the wallet balance is sufficient
  //   if (walletBalance < totalAmount) {
  //     toast.error('Insufficient balance. Please recharge your wallet.');
  //     return;
  //   }

  //   // Step 1: Upload image to Firebase Storage
  //   if (image) {
  //     try {
  //       const response = await fetch(image);
  //       const blob = await response.blob();

  //       // Create a reference to Firebase Storage
  //       const fileRef = ref(Storage, `branding_images/${user.uid}_${Date.now()}.png`);
        
  //       // Upload the image using uploadBytesResumable
  //       const uploadTask = uploadBytesResumable(fileRef, blob);
        
  //       uploadTask.on('state_changed', 

  //         (error) => {
  //           toast.error('Error uploading image: ' + error.message);
  //         }, 
  //         async () => {
  //           // Once upload is complete, we can get the download URL
  //           const imageURL = await getDownloadURL(uploadTask.snapshot.ref);  // This is correct for Firebase v9+ modular SDK

  //           // Step 2: Store branding details in Firestore with image URL
  //           const brandingRef = collection(db, 'users', user.uid, 'branding');
  //           const brandingData = {
  //             quantity,
  //             price,
  //             productName: 'Neck Label',
  //             Neck_label: imageURL, // Store the image URL
  //             timestamp: Timestamp.now(),
  //           };

  //           await addDoc(brandingRef, brandingData);

  //           // Step 3: Update the wallet balance after successful data storage
  //           const updatedBalance = walletBalance - totalAmount;
  //           const walletRef = doc(db, 'users', user.uid, 'wallet', 'walletDetails');
  //           await updateDoc(walletRef, { balance: updatedBalance });

  //           // Step 4: Record the transaction in Firestore
  //           const transactionsRef = collection(walletRef, 'transactions');
  //           const transactionData = {
  //             type: 'Neck Labels',
  //             amount: totalAmount,
  //             balanceBefore: walletBalance,
  //             balanceAfter: updatedBalance,
  //             timestamp: Timestamp.now(),
  //           };

  //           await addDoc(transactionsRef, transactionData);

  //           toast.success('Neck Labels purchased successfully!');
  //           setShowConfirmModal(false); // Close confirmation modal
  //         }
  //       );
  //     } catch (error) {
  //       console.error('Error processing the order:', error);
  //       toast.error('Error submitting the order. Please try again.');
  //     }
  //   } else {
  //     toast.error('No image selected. Please upload an image.');
  //   }
  // };


  const handleConfirm = async () => {
    const totalAmount = price;
  
    // Check if the wallet balance is sufficient
    if (walletBalance < totalAmount) {
      toast.error('Insufficient balance. Please recharge your wallet.');
      return;
    }
  
    // Step 1: Upload image to Firebase Storage
    if (image) {
      try {
        const response = await fetch(image);
        const blob = await response.blob();
  
        // Create a reference to Firebase Storage
        const fileRef = ref(Storage, `branding_images/${user.uid}_${Date.now()}.png`);
  
        // Upload the image using uploadBytesResumable
        const uploadTask = uploadBytesResumable(fileRef, blob);
  
        uploadTask.on(
          'state_changed',
          null,
          (error) => {
            toast.error('Error uploading image: ' + error.message);
          },
          async () => {
            // Once upload is complete, get the download URL
            const imageURL = await getDownloadURL(uploadTask.snapshot.ref);
  
            // Step 2: Store branding details in Firestore
            const brandingRef = collection(db, 'users', user.uid, 'branding');
            const brandingData = {
              quantity,
              price,
              productName: 'Neck Label',
              Neck_label: imageURL, // Store the image URL
              timestamp: Timestamp.now(),
            };
  
            // Add the document and retrieve the document ID
            const docRef = await addDoc(brandingRef, brandingData);
  
            // Update the document to include the ID as a field
            await updateDoc(docRef, { id: docRef.id });
  
            // Step 3: Update the wallet balance after successful data storage
            const updatedBalance = walletBalance - totalAmount;
            const walletRef = doc(db, 'users', user.uid, 'wallet', 'walletDetails');
            await updateDoc(walletRef, { balance: updatedBalance });
  
            // Step 4: Record the transaction in Firestore
            const transactionsRef = collection(walletRef, 'transactions');
            const transactionData = {
              type: 'Neck Labels',
              amount: totalAmount,
              balanceBefore: walletBalance,
              balanceAfter: updatedBalance,
              timestamp: Timestamp.now(),
            };
  
            await addDoc(transactionsRef, transactionData);
  
            toast.success('Neck Labels purchased successfully!');
            setShowConfirmModal(false); // Close confirmation modal
          }
        );
      } catch (error) {
        console.error('Error processing the order:', error);
        toast.error('Error submitting the order. Please try again.');
      }
    } else {
      toast.error('No image selected. Please upload an image.');
    }
  };
  
  // Remove the uploaded image
  const removeImage = () => {
    setImage(null);
    setIsImageSelected(false);
  };

  return (
    <div className="contact-us">
      <Header />
      <div className="contact-container">
        <Sidebar />
        <div className="main-content">
        {page === 'select' && (
  <div className="select-container">
    <h2>Branding</h2>
    <div className="option-button-container">
      {/* Neck Labels Button with Image */}
      <div className="option-button-wrapper">
        <button
          onClick={() => setPage('branding')}
          className="option-button"
          style={{
            backgroundImage: 'url(/neck-label.jpg)', // Path to image in the public folder
          }}
        />
        <span className="button-text">Neck Labels</span> {/* Text below the button */}
      </div>
       
      {/* Packaging Button with Image */}
      <div className="option-button-wrapper">
        <button
          onClick={() => toast.info('Packaging is coming soon!')}
          className="option-button"
          style={{
            backgroundImage: 'url(/packaging.jpg)', // Replace with the actual image URL
          }}
        />
        <span className="button-text">Packaging</span> {/* Text below the button */}
      </div>
    </div>
  </div>
)}


{page === 'branding' && (
  <div>
<div className="orders-section" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
  <h3>Quantity in stock</h3>
  {orders.length > 0 ? (
    <div>
      {/* Calculate the sum of all quantities */}
      <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>
       {orders.reduce((acc, order) => acc + order.quantity, 0)}
      </div>
    </div>
  ) : (
    <p>No previous orders found.</p>
  )}
</div>




    <div
      style={{
        width: '200px',
        height: '200px',
        border: '2px solid #000',
        margin: '0 auto',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f8f8f8',
      }}
    >
      {!isImageSelected ? (
        <>
          <label
            style={{
              fontSize: '20px',
              cursor: 'pointer',
              color: '#888',
              textAlign: 'center',
            }}
          >
             <img src='/upload.png' alt="" height= '33px' width="33px" />
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                opacity: 0,
                cursor: 'pointer',
              }}
            />
          </label>
          
        </>
      ) : (
        <>
          <img
            src={image}
            alt="Uploaded"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              borderRadius: '8px',
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              color: '#fff',
              padding: '5px 10px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            onClick={removeImage}
          >
            Remove
          </div>

          
        </>
        
      )}
      
    </div>
  
    <p style={{ textAlign: 'center', marginTop: '10px', fontSize: '14px', color: '#555' }}>
  2 X 2 inches
</p>
    {/* Quantity Controls */}
    <div style={{ marginTop: '11px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
    
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '18px',
          backgroundColor: '#f0f0f0',
          padding: '10px',
          borderRadius: '10px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <button
          onClick={decreaseQuantity}
          style={{
            backgroundColor: '#000',
            border: 'none',
            color: '#fff',
            fontSize: '20px',
            padding: '8px 12px',
            cursor: 'pointer',
            borderRadius: '5px',
            marginRight: '15px',
          }}
        >
          -
        </button>
        <span
          style={{
            fontSize: '18px', // Smaller size for quantity label
            fontWeight: 'bold',
            margin: '0 15px',
          }}
        >
          {quantity}
        </span>
        <button
          onClick={increaseQuantity}
          style={{
            backgroundColor: '#000',
            border: 'none',
            color: '#fff',
            fontSize: '20px',
            padding: '8px 12px',
            cursor: 'pointer',
            borderRadius: '5px',
            marginLeft: '15px',
          }}
        >
          +
        </button>
      </div>

      <h4 style={{ marginTop: '10px', textAlign: 'center' }}>Total Price: ₹{price}</h4>
    </div>

    {/* Confirm Button */}
    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
      <button
        onClick={() => setShowConfirmModal(true)}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#4CAF50',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        Confirm
      </button>
    </div>

    {/* Confirmation Modal */}
    {showConfirmModal && (
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: '20px',
          background: '#fff',
          border: '1px solid #ddd',
          borderRadius: '8px',
        }}
      >
        <h3>Are you sure you want to proceed?</h3>
        <p>Total Amount: ₹{price}</p>
        <div>
          <button
            onClick={handleConfirm}
            style={{
              padding: '10px 20px',
              backgroundColor: '#4CAF50',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
            }}
          >
            Confirm
          </button>
          <button
            onClick={() => setShowConfirmModal(false)}
            style={{
              padding: '10px 20px',
              backgroundColor: '#f44336',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              marginLeft: '10px',
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    )}
  </div>
)}
        </div>
      </div>
    </div>
  );
};

export default Branding;