import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { db, auth } from "../firebase";
import { Storage } from '../firebase';
import { collection, getDocs, updateDoc, doc as firestoreDoc, getDoc, addDoc, Timestamp } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL, listAll } from 'firebase/storage';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import Wallet from '../components/Wallet';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useModal } from '../components/ModalContext';
import '../style/placeorder.css'
import '../style/OrderSample.css'
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { ToWords } from "to-words";
import { numberToWords } from "amount-to-words";


Modal.setAppElement("#root");

const ProductSelectionPopup = ({ isOpen, onClose, products, onSelectProduct }) => {
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleSizeChange = (e) => {
    setSelectedSize(e.target.value);  
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setSelectedSize(""); // Reset size selection when new product is selected
  };

  const handleConfirmProduct = () => {
    if (!selectedSize) {
      alert("Please select a size!");
      return;
    }

    const productWithSize = { ...selectedProduct, selectedSize };
    onSelectProduct(productWithSize);
    setSelectedProduct(null); // Clear selected product
    setSelectedSize(""); // Reset size after selection
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="product-selection-modal">
      <h2>Select Product</h2>
      <div className="sample-order-product-list">
        {products.map((product) => (
          <div
            key={product.id}
            className="product-item"
            onClick={() => handleProductSelect(product)}
          >
            <img src={product.images.front} alt={product.productName} width="50" />
            <img src={product.images.back} alt={product.productName} width="50" />
            <div>
              <h3>{product.productName}</h3>
              <p>{product.designName}</p>
              <p>Price: Rs. {product.totalPrice}</p>
              <p>{product.description}</p>
            </div>
          </div>
        ))}
      </div>

      {/* If a product is selected, show size options */}
      {selectedProduct && (
  <div className="sticky-selectsize">
    <h3>Select Size for {selectedProduct.productName}</h3>
    <select value={selectedSize} onChange={handleSizeChange}>
      <option value="">Select Size</option>
      <option value="S">S</option>
      <option value="M">M</option>
      <option value="L">L</option>
      <option value="XL">XL</option>
    </select>
    <button onClick={handleConfirmProduct}>Confirm Product and Size</button>
  </div>
)}

      <button className="close-button" onClick={onClose}>
        Close
      </button>
    </Modal>
  );
};

const PlaceOrder = () => {
   const location = useLocation();
  //  const queryParams = new URLSearchParams(location.search);
  //  const shopId = queryParams.get('shop');
  const { design } = location.state || {};

      const user = auth.currentUser;
      if (!user) {
        throw new Error("User not logged in");
      }

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    pincode: "",
    state: "",
  });
  const [step, setStep] = useState("product");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState(design ? [{ ...design, selectedSize: "", quantity: 0  }] : []);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false); // Confirmation Modal state
  const [confirmBalance, setConfirmBalance] = useState(null);
  const [walletBalance, setWalletBalance] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false); 
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  const [selectedSize, setSelectedSize] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
//   const [paymentMethod, setPaymentMethod] = useState('Prepaid'); // Default to Prepaid
  const [shippingCharge, setShippingCharge] = useState(0);

  // Fetch products from Firebase
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const productsCollection = collection(db, 'users', user.uid, 'designs');
          const productsSnapshot = await getDocs(productsCollection);
          const productList = productsSnapshot.docs.map((firestoreDoc) => ({
            id: firestoreDoc.id,
            ...firestoreDoc.data(),
          }));
          setProducts(productList);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Calculate total price whenever selectedProducts changes
  useEffect(() => {
    const total = selectedProducts.reduce((sum, product) => sum + product.totalPrice, 0);
    setTotalPrice(total);
  }, [selectedProducts]);

  useEffect(() => {
    const total = selectedProducts.reduce(
      (sum, product) => sum + product.totalPrice * product.quantity,
      0
    );
    setTotalPrice(total);
  }, [selectedProducts]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddProductClick = () => {
    setIsModalOpen(true);
  };

  const handleDeleteProduct = (productId) => {
    setSelectedProducts((prevProducts) => prevProducts.filter(product => product.id !== productId));
  };

  const getSKUForSize = (skus, size) => {
    return skus.find((sku) => sku.endsWith(`-${size}`));
  };

  const handleProductSelect = (product) => {
    // Check if the product SKU already exists
    const existingProduct = selectedProducts.find(
      (p) => p.id === product.id && p.selectedSize === product.selectedSize
    );
  
    if (existingProduct) {
      // Increase quantity if the same product and size are selected again
      existingProduct.quantity += 1;
    } else {
      // Assign SKU based on the selected size (ensure SKU is correctly assigned)
      const selectedSKU = getSKUForSize(product.SKUs, product.selectedSize);
  
      if (selectedSKU) {
        setSelectedProducts((prevProducts) => [
          ...prevProducts,
          { ...product, quantity: 1, selectedSKU },
        ]);
      } else {
        console.warn("No matching SKU found for size:", product.selectedSize);
      }
    }
  
    setIsModalOpen(false);
  };
 

  const validateForm = () => {
    return Object.values(formData).every((value) => value.trim() !== "");
  };


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
            setUserId(user.uid); // Optionally set the email
        } else {
            toast.error('User not authenticated.');
        }
    });

    return () => unsubscribe();
}, []);

      useEffect(() => {
        const fetchWalletBalance = async () => {
            if (!userId) return;

            try {
                const walletRef = firestoreDoc(db, 'users', userId, 'wallet', 'walletDetails');
                const walletSnap = await getDoc(walletRef);
                if (walletSnap.exists()) {
                    const walletData = walletSnap.data();
                    setWalletBalance(walletData.balance);
                } else {
                    toast.error('Wallet not found. Please recharge.');
                }
            } catch (error) {
                console.error('Error fetching wallet balance:', error);
                toast.error('Error fetching wallet balance.');
            }
        };

        fetchWalletBalance();
    }, [userId]);


    const toWords = new ToWords({
              localeCode: "en-IN",
              converterOptions: {
                currency: true,
                ignoreDecimal: false,
                ignoreZeroCurrency: false,
                doNotAddOnly: false,
                currencyOptions: {
                  name: "Rupee",
                  plural: "Rupees",
                  symbol: "₹",
                  fractionalUnit: {
                    name: "Paisa",
                    plural: "Paise",
                    symbol: "",
                  },
                },
              },
            });


    const paymentMethod = 'Prepaid'; 

    // const calculateShippingCharge = (order) => {
    //     const specialStates = [
    //       "North East", "J & K", "Ladakh", "Andaman & Nicobar", 
    //       "Himachal Pradesh", "Sikkim", "Lakshadweep",
    //       "Arunachal Pradesh", "Assam", "Manipur", "Meghalaya", 
    //       "Mizoram", "Nagaland", "Tripura"
    //     ];
    
    //     const billingState = order.state;
    //     const sellingPrice = order.totalPrice;
    //     const itemWeight = 0.25; // Base weight for calculating shipping cost
    
    //     let shippingCharge = 0;
    
    //     if (billingState && specialStates.includes(billingState)) {
    //       if (paymentMethod === "Prepaid") {
    //         shippingCharge = 85; // Default for Prepaid in special states
    //       }
    //     } else {
    //       if (paymentMethod === "Prepaid") {
    //         shippingCharge = 65; // Default for Prepaid in regular states
    //       }
    //     }


    //     let totalWeight = 0;
    //     for (const product of selectedProducts) {
    //         if (product.quantity && typeof product.quantity === "number") {
    //             // Multiply product quantity by item weight for each product
    //             const productWeight = product.quantity * itemWeight;
    //             totalWeight += productWeight; // Add to total weight
    //         } else {
    //             console.warn("Invalid quantity for product:", product);
    //         }
    //     }
    
    //     // Additional weight-based shipping charge
    //     // const weightGroups = Math.ceil(selectedProducts.length * itemWeight / 0.5);

    //     const weightGroups = Math.ceil(totalWeight / 0.5);

    //     shippingCharge *= weightGroups;
    
    //     return shippingCharge;
    // };
    
    const calculateShippingCharge = (order) => {
      const userId = auth.currentUser?.uid; // Get the userId (assuming this is available)
      const specialStates = [
        "North East", "J & K", "Ladakh", "Andaman & Nicobar", 
        "Himachal Pradesh", "Sikkim", "Lakshadweep",
        "Arunachal Pradesh", "Assam", "Manipur", "Meghalaya", 
        "Mizoram", "Nagaland", "Tripura"
      ];
    
      const billingState = order.state;
      const sellingPrice = order.totalPrice;
      const itemWeight = 0.25; // Base weight for calculating shipping cost
    
      let shippingCharge = 0;
    
      // Condition for specific user (change "specificUserIdHere" to the actual user ID)
      if (userId === "hl6gcaUDIFXQkYUxppfAAHycLtO2") {
          if (paymentMethod === "Prepaid") {
              // Custom shipping charge for prepaid for this specific user
              shippingCharge = 55; // Example: Custom charge for specific user
              console.log("Custom Prepaid shipping charge for this user: ₹40");
          }
      } else {
          // Default behavior for other users
          if (billingState && specialStates.includes(billingState)) {
            if (paymentMethod === "Prepaid") {
              shippingCharge = 85; // Default for Prepaid in special states
            }
          } else {
            if (paymentMethod === "Prepaid") {
              shippingCharge = 65; // Default for Prepaid in regular states
            }
          }
      }
  
      let totalWeight = 0;
      for (const product of selectedProducts) {
          if (product.quantity && typeof product.quantity === "number") {
              // Multiply product quantity by item weight for each product
              const productWeight = product.quantity * itemWeight;
              totalWeight += productWeight; // Add to total weight
          } else {
              console.warn("Invalid quantity for product:", product);
          }
      }
    
      // Calculate the weight groups based on total weight
      const weightGroups = Math.ceil(totalWeight / 0.5);
  
      // Multiply shipping charge by weight groups
      shippingCharge *= weightGroups;
    
      return shippingCharge;
  };
  
    const calculateShippingWithGST = (order) => {
      // Calculate the shipping charge first using the existing logic
      let shippingCharge = calculateShippingCharge(order);
      
      // Apply 18% GST on the shipping charge
      const gst = shippingCharge * 0.18; // 18% of shipping charge
      
      // Add the GST to the original shipping charge
      const totalShippingWithGST = shippingCharge + gst;
      
      return totalShippingWithGST;
  };

    useEffect(() => {
        const orderDetails = {
          totalPrice,
          state: formData.state,
        };
        const calculatedShippingCharge = calculateShippingCharge(orderDetails);
        setShippingCharge(calculatedShippingCharge);
      }, [totalPrice, formData.state]); // Recalculate shipping charge


    //   const handlePaymentMethodChange = (e) => {
    //     setPaymentMethod(e.target.value);
    // };

  const handleOrderSubmit = async (e) => {
    if (!validateForm()) {
      alert("Please fill in all required fields.");
      return;
    }

    // Fetch wallet details
    e.preventDefault();
    if (walletBalance < (totalPrice + (totalPrice * 0.05 ) + shippingCharge + (shippingCharge * 0.18))) {
        toast.error('Insufficient balance. Please recharge your wallet.');
        return;
    }
    setIsModalOpen(true); // Show the confirmation modal

    // Check if the balance is sufficient
    if (walletBalance <(totalPrice + (totalPrice * 0.05 ) + shippingCharge + (shippingCharge * 0.18))) {
      toast.error('Insufficient balance. Recharge your wallet.', { onClose: () => setIsModalOpen(true) });
      return;
    }

    // Set balance after deduction and show confirmation modal
    setConfirmBalance(walletBalance - (totalPrice + (totalPrice * 0.05 ) + shippingCharge + (shippingCharge * 0.18)));
    setShowConfirmModal(true);
  };

  const handleSizeChange = (e, index) => {
    const selectedSize = e.target.value;
    const updatedProducts = [...selectedProducts];
    const product = updatedProducts[index];
  
    // Get SKU for the selected size
    const selectedSKU = getSKUForSize(product.SKUs, selectedSize);
  
    if (selectedSKU) {
      product.selectedSize = selectedSize;
      product.selectedSKU = selectedSKU;  // Update the SKU based on selected size
    } else {
      console.warn("No matching SKU found for size:", selectedSize);
      return;
    }
  
    setSelectedProducts(updatedProducts);
  };



      

  // const handleConfirmOrder = async () => {
  //   const unitee_order_id = `sample-${Date.now() % 1000000000}${Math.floor(Math.random() * 100)}`;

  
  
  //   const designSnapshot = await getDoc(doc(db, "users", user.uid, "designs", design.id));
  //   let pickupLocation = "Printing Unit"; // Default location if no match
  //   if (designSnapshot.exists()) {
  //     const designData = designSnapshot.data();
  
  //     // Ensure that printMethod exists in designData
  //     if (designData.printMethod) {
  //       // Determine the pickup location based on the print method
  //       if (designData.printMethod === "DTG Epson" || designData.printMethod === "DTF Epson") {
  //         pickupLocation = "Printing Unit 2";
  //       } else if (designData.printMethod === "DTG Brother") {
  //         pickupLocation = "Printing Unit";
  //       }
  //     } else {
  //       console.warn('Print method is missing in design data. Defaulting to "Printing Unit".');
  //     }
  
  //     console.log("Design data found:", designData);
  //   } else {
  //     console.warn(`No design found for SKU: ${design.id}`);
  //     return;
  //   }
  
  //   // Prepare order data
  //   const orderData = {
  //     order_id: unitee_order_id,
  //     customer: {
  //       name: formData.name,
  //       email: formData.email,
  //       address: formData.address,
  //       city: formData.city,
  //       state: formData.state,
  //       pinCode: formData.pincode,
  //       phone: formData.phone,
  //     },
  //     products: selectedProducts.map((product) => ({
  //       productName: product.productName,
  //       totalPrice: product.totalPrice * product.quantity,
  //       quantity: product.quantity,
  //       SKU: product.selectedSKU,
  //       selectedSize: product.selectedSize,
  //     })),
  //     totalPrice: totalPrice + shippingCharge + (totalPrice + shippingCharge) * 0.05,
  //     pickupLocation: pickupLocation, // Send the correct pickup location
  //     userId: user.uid,
  //     status:'confirmed',
  //     isPaid: true,
  //     timestamp: Timestamp.now(),
  //   };
  
  //   // Send order data to the backend API
  //   try {
  //     const response = await axios.post('https://d712-202-72-224-58.ngrok-free.app/create-sample-order', orderData, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //       }
  //     });
  //     console.log('Order created on Shiprocket successfully:', response.data);
  
  //     // Handle success (e.g., show success message, navigate, etc.)
  
  //     // After successful order creation, update wallet balance in Firestore
  //     const walletRef = doc(db, 'users', user.uid, 'wallet', 'walletDetails');
  //     await updateDoc(walletRef, {
  //       balance: confirmBalance,
  //     });
      

  //      const transactionsRef = collection(walletRef, 'transactions');
  //                             const transactionData = {
  //                                 type: unitee_order_id,
  //                                 amount: totalPrice + shippingCharge + (totalPrice + shippingCharge) * 0.05,
  //                                 balanceBefore: walletBalance,
  //                                 balanceAfter: confirmBalance,
  //                                 timestamp: Timestamp.now(),
  //                             };
  //                             console.log("Recording transaction data:", transactionData);
  //                             await addDoc(transactionsRef, transactionData);
  //                             console.log("Transaction recorded successfully.");
      


  //     toast.success("Order submitted successfully!");
  
  //     // Navigate to the orders page
  //     // navigate(`/OrdersPage?shop=${shopId}`);
  
  //   } catch (error) {
  //     console.error('Error submitting order:', error);
  //     toast.error("Failed to submit the order. Please try again.");
  //   }
  // };


  function calculateVolumetricWeight(selectedProducts) {
    const baseDimensions = { L: 24, B: 24, H: 4 }; // Base dimensions for the first 2 items
    const stepIncrease = { L: 4, B: 4, H: 1 }; // Dimensions increase per additional 2 products
    const weightPerSlab = 0.5; // Each slab (2 products for t-shirts) adds 0.5 kg
  
    let totalWeight = 0;
    let totalLength = 0;
    let totalBreadth = 0;
    let totalHeight = 0;
  
    selectedProducts.forEach(product => {
        const quantity = product.quantity;
        const name = product.productName.toLowerCase(); // Make the product name lowercase for easier comparison
  
        if (quantity && typeof quantity === "number" && quantity > 0) { // Ensure valid quantity
            let slabs;
            let productWeight;
  
            // Check if the product is a "t-shirt" or "baby tee" and determine slabs accordingly
            if (name.includes("t-shirt")) {
                slabs = Math.ceil(quantity / 2); // 2 products per slab for t-shirt
                productWeight = slabs * weightPerSlab; // Each slab adds 0.5 kg
            } else if (name.includes("baby tee")) {
                slabs = Math.ceil(quantity / 3); // 3 products per slab for baby tee
                productWeight = slabs * weightPerSlab; // Each slab adds 0.5 kg
            } else {
                slabs = Math.ceil(quantity / 2); // Default for other products
                productWeight = slabs * weightPerSlab; // Each slab adds 0.5 kg
            }
  
            // Calculate the dimensions per slab (same for both t-shirt and baby tee)
            let productLength = baseDimensions.L + (slabs - 1) * stepIncrease.L;
            let productBreadth = baseDimensions.B + (slabs - 1) * stepIncrease.B;
            let productHeight = baseDimensions.H + (slabs - 1) * stepIncrease.H;
  
            // Add the weight and dimensions of the current product
            totalWeight += productWeight;
            totalLength = Math.max(totalLength, productLength); // Ensure the largest dimension
            totalBreadth = Math.max(totalBreadth, productBreadth); // Ensure the largest dimension
            totalHeight = Math.max(totalHeight, productHeight); // Ensure the largest dimension
        }
    });
  
    // Return the final dimensions and weight
    return {
        length: totalLength,
        breadth: totalBreadth,
        height: totalHeight,
        weight: totalWeight,
    };
  }


  const handleConfirmOrder = async () => {

    if (isProcessing) {
      // If the order is already being processed, prevent further clicks
      return;
    }
  
    setIsProcessing(true); // Set to true when processing starts

    const unitee_order_id = `sample-${Date.now() % 1000000000}${Math.floor(Math.random() * 100)}`;
  
    const designSnapshot = await getDoc(firestoreDoc(db, "users", user.uid, "designs", design.id));
    let pickupLocation = "Printing Unit"; // Default location if no match
    if (designSnapshot.exists()) {
      const designData = designSnapshot.data();
  
      // Ensure that printMethod exists in designData
      if (designData.printMethod) {
        if (designData.printMethod === "DTG Epson" || designData.printMethod === "DTF Epson") {
          pickupLocation = "Printing Unit 2";
        } else if (designData.printMethod === "DTG Brother") {
          pickupLocation = "Printing Unit";
        }
      }
    } else {
      console.warn(`No design found for SKU: ${design.id}`);
      return;
    }
  
    // Fetch brand information from Firestore
    const userRef = firestoreDoc(db, "users", user.uid);
    const userSnap = await getDoc(userRef);
    if (!userSnap.exists()) {
      console.error("Brand information not found.");
      return;
    }
    const userData = userSnap.data();

    const {length , breadth, height, weight} = calculateVolumetricWeight(selectedProducts)
    
  
    // Prepare order data
    const orderData = {
      order_id: unitee_order_id,
      customer: {
        name: formData.name,
        email: formData.email,
        address: formData.address,
        city: formData.city,
        state: formData.state,
        pinCode: formData.pincode,
        phone: formData.phone,
      },
      products: selectedProducts.map((product) => ({
        designName: product.designName,
        productName: product.productName,
        sellingPrice: product.sellingPrice * product.quantity,
        quantity: product.quantity,
        SKU: product.selectedSKU,
        selectedSize: product.selectedSize,
      })),
      sub_total: selectedProducts.reduce((total, product) => {
        return total + product.sellingPrice * product.quantity; // Summing up the selling price of all products
      }, 0),
      totalPrice: (totalPrice + (totalPrice * 0.05 ) + (shippingCharge * 0.18)),
      pickupLocation: pickupLocation, // Send the correct pickup location
      userId: user.uid,
      status: 'confirmed',
      length: length,
      breadth: breadth,
      height: height,
      weight: weight,
      isPaid: true,
      timestamp: Timestamp.now(),
    };
  
    // Create Invoice

    
    // Rest of the code for creating the invoice and uploading it...
    

    const doc = new jsPDF();
    
    // Header Section
    doc.setFontSize(20);
        
    doc.text('TAX INVOICE', 150, 15, null, null, 'center');


    doc.setFontSize(10);
    doc.setFont("Helvetica", "normal");
    doc.text("UNITEE STUDIOS PRIVATE LIMITED", 10, 20);
    doc.text("Maharashtra, India", 10, 25);
    doc.text("GSTIN 27AADCU3575G1Z5", 10, 30);
    doc.text("rajprit@uniteeculture.com", 10, 35);
    doc.text("1ST FLOOR, PLOT 304/E/, 3, WAKHARIA BUILDING Vithalbhai Patel Road", 10, 40);
    doc.text("MUMBAI", 10, 45);
  
    // Company Information (Brand info)





    const mainInfo = {
      name: 'UNITEE STUDIOS PRIVATE LIMITED',
  };

  const invoiceDate = new Date().toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: '2-digit',
  });


  const storageRef = ref(Storage, 'invoices/');

  // Get the list of items (invoices) in the 'invoices/' folder
  const listResult = await listAll(storageRef);
  let nextInvoiceNumber = 1;  // Default to 1 if no invoices exist
  
  if (listResult.items.length > 0) {
      // Extract the numeric part of each file name (e.g., 'invoice_001.pdf')
      const invoiceNumbers = listResult.items
          .map(item => item.name.match(/invoice_(\d+)\.pdf$/))  // Adjusting regex to capture invoice number
          .filter(match => match)  // Filter out invalid names
          .map(match => parseInt(match[1], 10));  // Parse the numeric part of the invoice number
  
      // Find the highest invoice number and increment it
      if (invoiceNumbers.length > 0) {
          nextInvoiceNumber = Math.max(...invoiceNumbers) + 1;  // Increment the highest invoice number by 1
      }
  }
  
  // Format the invoice number (e.g., 'UC/2024-25/001')
  const invoiceNumber = `UC/2024-25/${String(nextInvoiceNumber).padStart(3, '0')}`;

  
    // Invoice Details
    doc.text("Invoice Details", 150, 25, null, null, 'center');
    doc.setFontSize(10);
    doc.text(`Invoice Date: ${invoiceDate}`, 140, 30);
    doc.text(`Invoice Number: ${invoiceNumber}`, 140, 35);
  
    // Billing Information (Customer info)
    doc.text("Bill To", 10, 60);
    doc.text(`Name: ${userData.brandName}`, 10, 65);
    doc.text(`Address: ${userData.address}`, 10, 70);
    doc.text(`State: ${userData.state}`, 10, 75);
    doc.text(`Pin Code: ${userData.pincode}`, 10, 80);
    doc.text(`GST Number: ${userData.gstNumber}`, 10, 85);


    doc.text("Ship To", 140, 60);
    doc.text(`Name: ${formData.name}`, 140, 65);
    doc.text(`Address: ${formData.address}`, 140, 70);
    doc.text(`City: ${formData.city}`, 140, 75);
    doc.text(`State: ${formData.state}`, 140, 80);           
    doc.text(`Pin Code: ${formData.pincode}`, 140, 85);
  
    // Item Details Table
    // const itemsTableY = 90;
    // autoTable(doc, {
    //   startY: itemsTableY,
    //   head: [['#', 'Item & Description', 'HSN/SAC', 'Qty', 'Rate', 'Amount']],
    //   body: selectedProducts.map((product, index) => {
    //     const itemPrice = product.totalPrice * product.quantity;
    //     return [
    //       index + 1,
    //       product.productName,
    //       '610910',  // Example HSN code
    //       product.quantity,
    //       itemPrice.toFixed(2),
    //       itemPrice.toFixed(2),
    //     ];
    //   }),
    //   theme: 'grid',
    //   styles: { fontSize: 10 },
    // });

    const itemsTableY = 90;
    autoTable(doc, {
        startY: itemsTableY,
        head: [
            ['#', 'Item & Description', 'HSN/SAC', 'Qty', 'Rate', 
             userData.state === "Maharashtra" ? 'CGST %' : 'IGST %', 
             userData.state === "Maharashtra" ? 'CGST Amt' : 'IGST Amt', 
             userData.state === "Maharashtra" ? 'SGST %' : '', 
             userData.state === "Maharashtra" ? 'SGST Amt' : '', 
             userData.state !== "Maharashtra" ? 'IGST %' : '', 
             userData.state !== "Maharashtra" ? 'IGST Amt' : '', 
             'Amount'
            ]
        ],
        body: selectedProducts.map((product, index) => {
            // Calculate the individual item price (including shipping)
            const itemPrice = (product.totalPrice || 0) * product.quantity;  // Item price + shipping
            let cgstAmt = 0;
            let sgstAmt = 0;
            let igstAmt = 0;
            let taxAmount = 0;
    
            if (userData.state === "Maharashtra") {
                // **CGST and SGST for Maharashtra**
                cgstAmt = (itemPrice * 0.025).toFixed(2);  // 2.5% CGST
                sgstAmt = (itemPrice * 0.025).toFixed(2);  // 2.5% SGST
                taxAmount = (parseFloat(cgstAmt) + parseFloat(sgstAmt)).toFixed(2);  // Total CGST + SGST
            } else {
                // **IGST for other states**
                igstAmt = (itemPrice * 0.05).toFixed(2);  // 5% IGST
                taxAmount = igstAmt;  // Only IGST
            }
    
            // Calculate total amount per item (including tax)
            const amount = (itemPrice + parseFloat(cgstAmt || igstAmt) + parseFloat(sgstAmt || 0)).toFixed(2);
    
            return [
                index + 1,
                product.productName || 'Unknown',
                '610910',  // Example HSN code
                product.quantity,
                itemPrice.toFixed(2),
                // **Display CGST and SGST for Maharashtra, IGST for others**
                userData.state === "Maharashtra" ? '2.5%' : '', 
                userData.state === "Maharashtra" ? cgstAmt : '',
                userData.state === "Maharashtra" ? '2.5%' : '', 
                userData.state === "Maharashtra" ? sgstAmt : '',
                userData.state !== "Maharashtra" ? '5%' : '', 
                userData.state !== "Maharashtra" ? igstAmt : '',
                amount,
            ];
        }),
        theme: 'grid',
        styles: { fontSize: 10 },
    });

                
                // Tax and Summary Table
                let totalTaxableValue = 0;
                let totalCGST = 0;
                let totalSGST = 0;
                let totalIGST = 0;
                
                selectedProducts.forEach(product => {
                    // Calculate the item price (including shipping)
                    const itemPrice = (product.totalPrice || 0);  // Item price + shipping
                    const taxableValue = itemPrice * product.quantity;
                    totalTaxableValue += taxableValue;
                
                    // **Apply CGST, SGST for Maharashtra and IGST for others**
                    if (userData.state === "Maharashtra") {
                        totalCGST += taxableValue * 0.025;  // **CGST**
                        totalSGST += taxableValue * 0.025;  // **SGST**
                    } else {
                        totalIGST += taxableValue * 0.05;  // **IGST**
                    }
                });
                
                const taxSummaryY = doc.autoTable.previous.finalY + 10;
                autoTable(doc, {
                    startY: taxSummaryY,
                    head: [['HSN/SAC', 'Taxable Value', 
                      userData.state === "Maharashtra" ? 'CGST %' : 'IGST %', 
                      userData.state === "Maharashtra" ? 'CGST Amt' : 'IGST Amt', 
                      userData.state === "Maharashtra" ? 'SGST %' : '', 
                      userData.state === "Maharashtra" ? 'SGST Amt' : '', 
                      userData.state !== "Maharashtra" ? 'IGST %' : '', 
                      userData.state !== "Maharashtra" ? 'IGST Amt' : '',
                        'Total Tax Amount']],
                    body: [
                        ['610910', totalTaxableValue.toFixed(2),
                            // **Display CGST/SGST/IGST based on state**
                            userData.state === "Maharashtra" ? '2.5%' : '', 
                            userData.state === "Maharashtra" ? totalCGST.toFixed(2) : '',
                            userData.state === "Maharashtra" ? '2.5%' : '', 
                            userData.state === "Maharashtra" ? totalSGST.toFixed(2) : '',
                            userData.state !== "Maharashtra" ? '5%' : '', 
                            userData.state !== "Maharashtra" ? totalIGST.toFixed(2) : '',
                            (totalCGST + totalSGST + totalIGST).toFixed(2)]
                    ],
                    theme: 'grid',
                    styles: { fontSize: 10 },
                });



                const shippingBase = (shippingCharge);  // Calculate base shipping charge (before 18% tax)
                const shippingTax = (shippingCharge * 0.18);  // Calculate the tax part (18% of base)
                
                // Now `shippingBase`, `shippingTax`, and `shippingCharge` are dynamically calculated
                
                const shippingTableY = doc.autoTable.previous.finalY + 10;
                autoTable(doc, {
                    startY: shippingTableY,
                    head: [['SAC', 'Shipping Charge', 'Rate (18%)','Total Shipping Charge' ]],
                    body: [
                        ['9965', shippingBase, shippingTax, ( shippingCharge +(shippingCharge * 0.18) ) ],    // Total shipping charge including tax
                    ],
                    theme: 'grid',
                    styles: { fontSize: 10 },
                });
            
            
            // Final Amount Summary
            const totalAmount = totalTaxableValue + totalCGST + totalSGST + totalIGST + ( shippingCharge +(shippingCharge * 0.18));
            const finalAmountY = doc.autoTable.previous.finalY + 10;
            doc.text(`Amount Chargeable (in words): ${toWords.convert(totalAmount)}`, 10, finalAmountY);
            doc.text(`Total: ${totalAmount.toFixed(2)}`, 150, finalAmountY + 5);
            doc.text(`Balance Due: ${totalAmount.toFixed(2)}`, 150, finalAmountY + 10);
            
            // Footer Notes
            doc.text("Thanks for your business.", 10, finalAmountY + 25);
            doc.text("Authorized Signature", 150, finalAmountY + 25);
            



  
    // Upload Invoice to Firebase Storage

    const invoiceFileName = `invoice_${nextInvoiceNumber}.pdf`;  // Updated to use nextInvoiceNumber
    const pdfBlob = doc.output('blob');  // Generate the PDF blob as usual

    // Upload the invoice to Firebase Storage
    const invoiceRef = ref(Storage, `invoices/${invoiceFileName}`);
    const uploadTask = uploadBytesResumable(invoiceRef, pdfBlob);

  
    // Observe upload progress
    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      error => {
        console.error('Error uploading invoice:', error);
        toast.error("Error uploading invoice");
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log('Invoice uploaded, download URL:', downloadURL);

          orderData.invoiceUrl = downloadURL;
  
          // Update order with the invoice URL
          // const orderRef = firestoreDoc(db, 'users', user.uid, 'orders', unitee_order_id);
          // await updateDoc(orderRef, {
          //   invoiceUrl: downloadURL, // Store invoice URL
          // });
          // console.log('Order updated with invoice URL.');
  
          // Send order data to backend API
          const response = await axios.post('https://ufb-1.onrender.com/create-prepaidsample-order', orderData, {
            headers: { 'Content-Type': 'application/json' },
          });
          console.log('Order created on Shiprocket successfully:', response.data);
  
          // Update wallet balance
          const walletRef = firestoreDoc(db, 'users', user.uid, 'wallet', 'walletDetails');
          await updateDoc(walletRef, { balance: confirmBalance });
  
          // Record transaction
          const transactionsRef = collection(walletRef, 'transactions');
          const transactionData = {
            type: unitee_order_id,
            amount: (totalPrice + (totalPrice * 0.05 ) + shippingCharge + (shippingCharge * 0.18)),
            balanceBefore: walletBalance,
            balanceAfter: confirmBalance,
            timestamp: Timestamp.now(),
          };
          await addDoc(transactionsRef, transactionData);
  
          toast.success("Order submitted successfully!");
          setShowConfirmModal(false);

        } catch (error) {
          console.error('Error handling completed upload:', error);
          toast.error("Error completing the order.");
        }
      }
    );
  };
  
  

  // const handleQuantityChange = (e, index) => {
  //   const updatedProducts = [...selectedProducts];
  //   const product = updatedProducts[index];
  //   const newQuantity = parseInt(e.target.value, 10);
  
  //   // Only update if the quantity is greater than 0
  //   if (newQuantity >= 0) {
  //     product.quantity = newQuantity;
  //     setSelectedProducts(updatedProducts);
  //   }
  // };
  
  const handleQuantityChange = (e, index) => {
    const updatedProducts = [...selectedProducts];
    const product = updatedProducts[index];
    const newQuantity = e.target.value;
  
    // Handle input where quantity is empty (let it be empty until the user types a valid value)
    if (newQuantity === "") {
      product.quantity = "";  // Temporarily empty until user enters something valid
    } else {
      const parsedQuantity = parseInt(newQuantity, 10);
  
      // Only update if parsedQuantity is a valid number and greater than or equal to 1
      if (!isNaN(parsedQuantity) && parsedQuantity >= 1) {
        product.quantity = parsedQuantity;
      } else {
        return; // Do not update if the parsed value is not valid
      }
    }
  
    setSelectedProducts(updatedProducts);
  };
  

  

  if (loading) {
    return <div>Loading products...</div>;
  }

  return (
    <div className="placeorder-page">
    <Header />
    <div className="products-container">
      <Sidebar />
      <div className="main-content">
        {step === "product" && (
          <div className="product-selection">
            <h1>Book a Sample</h1>
            <button className="add-order-button" onClick={handleAddProductClick}>
              Add Product to Order
            </button>
            

          {/* Modal for product selection */}
          <ProductSelectionPopup
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            products={products}
            onSelectProduct={handleProductSelect}
          />

          {/* Display selected products */}
          {selectedProducts.map((product, index) => (
   <div key={index} className="product-details">
      <h2>{product.productName}</h2>
      <p>{product.description}</p>
      <img src={product.images.front} alt="Front Mockup" width="150" />
      <img src={product.images.back} alt="Back Mockup" width="150" />
      <p>Total Price: Rs. {product.totalPrice * product.quantity}</p>

      {/* Size input */}
      <select
      value={product.selectedSize}
      onChange={(e) => handleSizeChange(e, index)}
    >
      <option value="">Select Size</option>
      <option value="S">S</option>
      <option value="M">M</option>
      <option value="L">L</option>
      <option value="XL">XL</option>

    </select>

      {/* Editable Quantity */}
      <div className="quantity-section">
        <label>Quantity:</label>
        <input
    type="number"  // Change from 'number' to 'text'
    value={product.quantity}
    onChange={(e) => handleQuantityChange(e, index)}
    // pattern="\d*" 
  />
      </div>

      <button 
      className="delete-product-button" 
      onClick={() => handleDeleteProduct(product.id)}>
      Delete
    </button>
   </div>
))}

          
          <p className="total-price">Total Price: Rs. {totalPrice}</p>
          <button className="submit-order-button" onClick={() => setStep("shipping")}>
            Proceed to Shipping
          </button>
        </div>
      )}

      {step === "shipping" && (
        <div className="shipping-form">
          <h1>Shipping Details</h1>
          {Object.keys(formData).map((field) => (
            <div key={field}>
              <label htmlFor={field}>{field.charAt(0).toUpperCase() + field.slice(1)}:</label>
              <input
                type="text"
                id={field}
                name={field}
                value={formData[field]}
                onChange={handleInputChange}
                required
              />
            </div>
          ))}
          <button className="submit-order-button" onClick={() => setStep("review")}>
            Review Order
          </button>
        </div>
      )}

{step === "review" && (
  <div className="review-order">
    <h1>Review Order</h1>
    <h2>Customer Details</h2>
    {Object.entries(formData).map(([key, value]) => (
      <p key={key}>
        {key.charAt(0).toUpperCase() + key.slice(1)}: {value}
      </p>
    ))}

    <h2>Products</h2>
    {selectedProducts.map((product, index) => (
      <div key={index} className="product-details">
        <h3>{product.productName}</h3>
        <p>Size: {product.selectedSize}</p>
        <p>Quantity: {product.quantity}</p> {/* Display quantity */}
        <p>Price: Rs. {product.totalPrice * product.quantity}</p> {/* Display price based on quantity */}
        <h3>Shipping Charge(tax inclusive): ₹{(shippingCharge + (shippingCharge * 0.18)).toFixed(2)}</h3>
      </div>
    ))}

    <h3>Total Price (inclusive of tax): Rs. {((totalPrice + (totalPrice * 0.05 ) + shippingCharge + (shippingCharge * 0.18))).toFixed(2)}</h3>
    
    <button className="submit-order-button" onClick={handleOrderSubmit}>
      Submit Order
    </button>
  </div>
)}

      {/* Confirmation Modal */}
      <Modal
        isOpen={showConfirmModal}
        onRequestClose={() => setShowConfirmModal(false)}
        className="confirmation-modal"
      >
        <h2>Confirm Your Order</h2>
        <p>Total Price: Rs. {((totalPrice + (totalPrice * 0.05 ) + shippingCharge + (shippingCharge * 0.18))).toFixed(2)}</p>
           <button className="confirm-button" onClick={handleConfirmOrder} disabled={isProcessing}>
        {isProcessing ? "Processing..." : "Confirm Order"}
        </button>
        <button className="cancel-button" onClick={() => setShowConfirmModal(false)}>
          Cancel
        </button>
      </Modal>
         </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PlaceOrder;