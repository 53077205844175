import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth, db } from '../firebase.js';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';

const Login = () => {
    const navigate = useNavigate();

    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    // This effect will check for the presence of `shop`, `installedAt`, and access token from URL parameters
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const shop = urlParams.get('shop');
        const installedAt = urlParams.get('installedAt');
        const accessToken = urlParams.get('accessToken'); // Shopify access token passed in the URL (if applicable)
    
        console.log("shop:", shop, "installedAt:", installedAt, "accessToken:", accessToken);  // Log parameters
    
        if (shop && installedAt && accessToken) {
            // Store the shop, installedAt, and accessToken values in localStorage
            localStorage.setItem('shop', shop);
            localStorage.setItem('installedAt', installedAt);
            localStorage.setItem('accessToken', accessToken);
    
            console.log("Stored in localStorage"); // Log when data is stored
        }
    }, []);
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true); // Start loading
    
        try {
            // Sign in the user with Firebase Authentication
            const userCredential = await signInWithEmailAndPassword(auth, emailAddress, password);
            const user = userCredential.user;

            // if (!user.emailVerified) {
            //     setError('Please verify your email before logging in.');
            //     return;
            // }
    
            // Fetch the user's profile from the `users` collection in Firestore
            const userDocRef = doc(db, 'users', user.uid); // 'users' is the collection name
            const userDocSnap = await getDoc(userDocRef);
    
            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                
                // Check if shop, installedAt, and accessToken are in localStorage
                const shop = localStorage.getItem('shop');
                const installedAt = localStorage.getItem('installedAt');
                const accessToken = localStorage.getItem('accessToken');
    
                // Only update these fields if they are not null or empty
                const updatedUserData = {
                    ...userData, // Preserve existing data
                    ...(shop && shop !== "null" ? { shop } : {}),
                    ...(installedAt && installedAt !== "null" ? { installedAt } : {}),
                    ...(accessToken && accessToken !== "null" ? { accessToken } : {}),
                };
    
                // Update the Firestore user profile with the merged data (if there are any changes)
                await setDoc(userDocRef, updatedUserData, { merge: true });
                console.log('User profile updated with Shopify data if available');
                
                // Navigate to the dashboard after login
                navigate('/dashboard');
            } else {
                // If no profile exists, create the user profile and store the data
                const shop = localStorage.getItem('shop');
                const installedAt = localStorage.getItem('installedAt');
                const accessToken = localStorage.getItem('accessToken');
    
                const newUserData = {
                    shop: shop || '',
                    installedAt: installedAt || '',
                    accessToken: accessToken || '',
                    createdAt: new Date(),
                };
    
                // Set the new user data in Firestore
                await setDoc(userDocRef, newUserData);
                console.log('New user profile created with Shopify data');
                
                // Navigate to the dashboard after login
                navigate('/dashboard');
            }
        } catch (err) {
            console.error('Error signing in:', err);
            setError('Invalid email or password. Please try again.');
        } finally {
            setLoading(false); // Stop loading
        }
    };
    


//version 2

// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import { auth, db } from '../firebase.js';
// import { signInWithEmailAndPassword } from 'firebase/auth';
// import { doc, getDoc, setDoc } from 'firebase/firestore';

// const Login = () => {
//     const navigate = useNavigate();

//     const [emailAddress, setEmailAddress] = useState('');
//     const [password, setPassword] = useState('');
//     const [error, setError] = useState('');
//     const [loading, setLoading] = useState(false);

//     // This effect will check for the presence of `shop`, `installedAt`, and access token from URL parameters
//     useEffect(() => {
//         const urlParams = new URLSearchParams(window.location.search);
//         const shop = urlParams.get('shop');
//         const installedAt = urlParams.get('installedAt');
//         const accessToken = urlParams.get('accessToken'); // Shopify access token passed in the URL (if applicable)
    
//         console.log("shop:", shop, "installedAt:", installedAt, "accessToken:", accessToken);  // Log parameters
    
//         if (shop && installedAt && accessToken) {
//             // Store the shop, installedAt, and accessToken values in localStorage
//             localStorage.setItem('shop', shop);
//             localStorage.setItem('installedAt', installedAt);
//             localStorage.setItem('accessToken', accessToken);
    
//             console.log("Stored in localStorage"); // Log when data is stored
//         }
//     }, []);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setError('');
//         setLoading(true); // Start loading

//         try {
//             // Sign in the user with Firebase Authentication
//             const userCredential = await signInWithEmailAndPassword(auth, emailAddress, password);
//             const user = userCredential.user;

//             // Fetch the user's profile from the `users` collection in Firestore
//             const userDocRef = doc(db, 'users', user.uid); // 'users' is the collection name
//             const userDocSnap = await getDoc(userDocRef);

//             if (userDocSnap.exists()) {
//                 const userData = userDocSnap.data();
                
//                 // Check if the user already has a store connected
//                 const storedShop = userData.shop;

//                 // If the user already has a connected shop, allow login without an error
//                 if (storedShop) {
//                     // If Shopify data exists in localStorage, update the Firestore data
//                     const shop = localStorage.getItem('shop');
//                     const installedAt = localStorage.getItem('installedAt');
//                     const accessToken = localStorage.getItem('accessToken');

//                     if (shop && installedAt && accessToken) {
//                         // If any Shopify data is missing, don't proceed with updating
//                         const updatedUserData = {
//                             ...userData, // Preserve existing data
//                             shop: shop, // Update if necessary
//                             installedAt: installedAt,
//                             accessToken: accessToken,
//                         };

//                         // Update Firestore with merged user data
//                         await setDoc(userDocRef, updatedUserData, { merge: true });
//                         console.log('User profile updated with shop, installedAt, and access token');
//                     }
//                 }
                
//                 // Clear localStorage after successful update or login
//                 // localStorage.removeItem('shop');
//                 // localStorage.removeItem('installedAt');
//                 // localStorage.removeItem('accessToken');

//                 // Navigate to the dashboard after login
//                 navigate('/dashboard');
//             } else {
//                 // If no profile exists, create the user profile and store the data
//                 const shop = localStorage.getItem('shop');
//                 const installedAt = localStorage.getItem('installedAt');
//                 const accessToken = localStorage.getItem('accessToken');

//                 // If any Shopify data is missing, don't proceed with creating the user profile
//                 if (!shop || !installedAt || !accessToken) {
//                     setError('Required Shopify information is missing.');
//                     setLoading(false);
//                     return;
//                 }

//                 // Set up the new user data with Shopify info
//                 const newUserData = {
//                     shop: shop,
//                     installedAt: installedAt,
//                     accessToken: accessToken,
//                     createdAt: new Date(),
//                 };

//                 // Set the new user profile in Firestore
//                 await setDoc(userDocRef, newUserData);
//                 console.log('New user profile created with Shopify data');
                
//                 // Clear localStorage after successful creation
//                 // localStorage.removeItem('shop');
//                 // localStorage.removeItem('installedAt');
//                 // localStorage.removeItem('accessToken');

//                 // Navigate to the dashboard after login
//                 navigate('/dashboard');
//             }
//         } catch (err) {
//             console.error('Error signing in:', err);
//             setError('Invalid email or password. Please try again.');
//         } finally {
//             setLoading(false); // Stop loading
//         }
//     };

    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Login</h1>
            <form onSubmit={handleSubmit} style={styles.form}>
                <label style={styles.label}>
                    Email Address:
                    <input
                        type="email"
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        required
                        style={styles.input}
                    />
                </label>
                <label style={styles.label}>
                    Password:
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        style={styles.input}
                    />
                </label>
                <button type="submit" style={styles.button} disabled={loading}>
                    {loading ? 'Logging in...' : 'Login'}
                </button>
                {error && <p style={styles.error}>{error}</p>}

                <p>
                    Don't have an Account? <Link to='/signup' style={styles.link}>Signup</Link>
                </p>
                <p>
                    <Link to='/login/forgetPassword' style={styles.link}>
                        Forgot Password?
                    </Link>
                </p>
            </form>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '500px',
        margin: '50px auto',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    },
    heading: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#000',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        marginBottom: '15px',
        fontSize: '1em',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginTop: '5px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        fontSize: '1em',
    },
    button: {
        padding: '10px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#000',
        color: 'white',
        fontSize: '1em',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
        marginTop: '10px',
        textAlign: 'center',
    },
    link: {
        color: '#000',
        textDecoration: 'none',
    },
};

export default Login;



// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import { auth, db } from '../firebase.js';
// import { signInWithEmailAndPassword } from 'firebase/auth';
// import { doc, getDoc } from 'firebase/firestore';

// const Login = () => {
//     const navigate = useNavigate();

//     const [emailAddress, setEmailAddress] = useState('');
//     const [password, setPassword] = useState('');
//     const [error, setError] = useState('');
//     const [loading, setLoading] = useState(false);

//     // This effect will check for the presence of `shop`, `installedAt`, and access token from URL parameters
//     useEffect(() => {
//         const urlParams = new URLSearchParams(window.location.search);
//         const shop = urlParams.get('shop');
//         const installedAt = urlParams.get('installedAt');
//         const accessToken = urlParams.get('accessToken'); // Shopify access token passed in the URL (if applicable)
    
//         console.log("shop:", shop, "installedAt:", installedAt, "accessToken:", accessToken);  // Log parameters
    
//         if (shop && installedAt && accessToken) {
//             // Store the shop, installedAt, and accessToken values in localStorage
//             localStorage.setItem('shop', shop);
//             localStorage.setItem('installedAt', installedAt);
//             localStorage.setItem('accessToken', accessToken);
    
//             console.log("Stored in localStorage"); // Log when data is stored
//         }
//     }, []);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setError('');
//         setLoading(true); // Start loading

//         try {
//             // Sign in the user with Firebase Authentication
//             const userCredential = await signInWithEmailAndPassword(auth, emailAddress, password);
//             const user = userCredential.user;

//             // Check if user exists in Firestore under the 'users' collection
//             const userDocRef = doc(db, 'users', user.uid);
//             const userDocSnap = await getDoc(userDocRef);

//             if (userDocSnap.exists()) {
//                 // If user exists in Firestore, proceed to dashboard
//                 navigate('/dashboard');
//             } else {
//                 // If user does not exist in Firestore, show an error
//                 setError('Account does not exist. Please sign up.');
//             }
//         } catch (err) {
//             console.error('Error signing in:', err);
//             setError('Invalid email or password. Please try again.');
//         } finally {
//             setLoading(false); // Stop loading
//         }
//     };

//     return (
//         <div style={styles.container}>
//             <h1 style={styles.heading}>Login</h1>
//             <form onSubmit={handleSubmit} style={styles.form}>
//                 <label style={styles.label}>
//                     Email Address:
//                     <input
//                         type="email"
//                         value={emailAddress}
//                         onChange={(e) => setEmailAddress(e.target.value)}
//                         required
//                         style={styles.input}
//                     />
//                 </label>
//                 <label style={styles.label}>
//                     Password:
//                     <input
//                         type="password"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                         required
//                         style={styles.input}
//                     />
//                 </label>
//                 <button type="submit" style={styles.button} disabled={loading}>
//                     {loading ? 'Logging in...' : 'Login'}
//                 </button>
//                 {error && <p style={styles.error}>{error}</p>}

//                 <p>
//                     Don't have an Account? <Link to='/signup' style={styles.link}>Signup</Link>
//                 </p>
//                 <p>
//                     <Link to='/login/forgetPassword' style={styles.link}>
//                         Forgot Password?
//                     </Link>
//                 </p>
//             </form>
//         </div>
//     );
// };

// const styles = {
//     container: {
//         maxWidth: '500px',
//         margin: '50px auto',
//         padding: '20px',
//         border: '1px solid #ccc',
//         borderRadius: '10px',
//         boxShadow: '0 0 10px rgba(0,0,0,0.1)',
//     },
//     heading: {
//         textAlign: 'center',
//         marginBottom: '20px',
//         color: '#000',
//     },
//     form: {
//         display: 'flex',
//         flexDirection: 'column',
//     },
//     label: {
//         marginBottom: '15px',
//         fontSize: '1em',
//     },
//     input: {
//         width: '100%',
//         padding: '10px',
//         marginTop: '5px',
//         borderRadius: '5px',
//         border: '1px solid #ccc',
//         fontSize: '1em',
//     },
//     button: {
//         padding: '10px',
//         borderRadius: '5px',
//         border: 'none',
//         backgroundColor: '#000',
//         color: 'white',
//         fontSize: '1em',
//         cursor: 'pointer',
//     },
//     error: {
//         color: 'red',
//         marginTop: '10px',
//         textAlign: 'center',
//     },
//     link: {
//         color: '#000',
//         textDecoration: 'none',
//     },
// };

// export default Login;
